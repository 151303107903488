import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../../constrants";

function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}
const deleteBanner = (uuid) => {
  const Uuid = {
    uuid: uuid,
  };
  axios
    .delete(BasedUrl + "/banners/deleteOne", { data: Uuid })
    .then((res) => {
      alert("ລົບແບນເນີ້ສຳເລັດ");
    })
    .catch((error) => {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ: \n" + error);
    });
};
const addBanner = async (link) => {
  try {
    await axios.post(BasedUrl + "/banners/add", link).then((res) => {
      alert("ເພີ່ມບົດຄວາມສຳເລັດ");
    });
  } catch (error) {
    alert("ເກີດບັນຫາໃນການເພີ່ມ ກະລູນາລອງໃໝ່: \n" + error);
  }
};

const BannersAdmin = () => {
  const [img, setImg] = useState("");
  const [link, setLink] = useState("");
  const [banners, setBanners] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (link !== "") {
      addBanner({ link });
    }
  }, [link]);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/banners/getAll").then((res) => {
        setBanners(res.data);
        setTrigger(false);
      });
    } catch (error) {
      return error;
    }
  }, [trigger]);

  return (
    <div className="col mt-5 text-center">
      <div className="bg-white p-5 mb-5">
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg(e.target.value)}
            className="form-control mb-3 text-center w-25"
            placeholder="ລິງຮູບພາບ"
            value={img}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button onClick={() => setLink(img)} className="btn btn-primary w-25">
            ເພິ່ມ
          </button>
        </div>
      </div>

      <h3>ລາຍການຮຸບແບນເນີ້</h3>
      <div className="bg-white mt-4">
        <div>
          <button
            onClick={() => setTrigger(true)}
            className="btn btn-primary mb-3 mt-5"
          >
            ໂຫລດໃໝ່
          </button>
        </div>
        <List>
          {banners.map((banner) => (
            <Banners
              key={banner.uuid}
              onClick={() => {
                if (window.confirm("ທ່ານຕ້ອງການລົບແບນເນີ້ນີ້ແທ້ບໍ່?") == true) {
                  deleteBanner(banner.uuid);
                  setTrigger(true);
                }
              }}
            >
              <div className="d-flex justify-content-center mb-3 mt-3">
                <SmallImage src={banner.link} />
              </div>
              <div className="d-flex justify-content-center mb-3">
                <button className="btn btn-danger w-50">ລົບ</button>
              </div>
            </Banners>
          ))}
        </List>
      </div>
    </div>
  );
};

const Image = styled.img`
  width: 600px;
  height: 250px;
`;

const SmallImage = styled.img`
  width: 150px;
  height: 120px;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Banners = styled.div`
  padding: 10px;
  &:hover {
    background-color: #eeeeee;
  }
`;
export default BannersAdmin;
