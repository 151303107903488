import styled from "styled-components";
import Post from "./Post";
import { useEffect, useState } from "react";
import axios from "axios";
import BasedUrl from "../constrants";
import { useLocation } from "react-router-dom";

const PostDetail = (props) => {
  const uuid = props.uuid;
  const [img, setImg] = useState("");
  const [topic, setTopic] = useState("");
  const [info, setInfo] = useState("");

  const location = useLocation();
  const post = location.state;

  useEffect(() => {
    try {
      axios
        .post(BasedUrl + "/posts/getOne", { uuid: uuid })
        .then((res) => {
          setTopic(res.data[0].topic);
          setImg(res.data[0].img);
          setInfo(res.data[0].info);
        })
        .catch((error) => {});
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <Container>
      <MyRow>
        <Image className="mb-5" src={post.img7} />
        <DESK>
          <div className="row">
            <div className="col">
              <img className="mb-5" src={post.img1} />
            </div>
            <div className="col">
              <img className="mb-5" src={post.img2} />
            </div>
            <div className="col">
              <img className="mb-5" src={post.img3} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img className="mb-5" src={post.img4} />
            </div>
            <div className="col">
              <img className="mb-5" src={post.img5} />
            </div>
            <div className="col">
              <img className="mb-5" src={post.img6} />
            </div>
          </div>
        </DESK>
        <MB>
          <div className="row">
            <img src={post.img1} />
            <img src={post.img2} />
            <img src={post.img3} />
            <img src={post.img4} />
            <img src={post.img5} />
            <img src={post.img6} />
          </div>
        </MB>
      </MyRow>
    </Container>
  );
};

export default PostDetail;

const MB = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const DESK = styled.div`
  display: block;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 150px;
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (max-width: 999px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 48%;
  object-fit: contain;
  height: auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const MyRow = styled.div`
  padding: 30px;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  width: 1610px;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media only screen and (max-width: 1600px) {
    width: 1200px;
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`;

const Container = styled.div`
  margin-top: 140px;
  display: flex;
  background-color: white;
  flex-direction: column;
  //overflow: hidden;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 860px) {
    display: block;
  }
`;
