import styled from "styled-components";
import { useLocation } from "react-router-dom";
import One from "./one";
import Two from "./two";
import Three from "./three";

const Com = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);

  return (
    <Container>
      {state === 1 && <One />}
      {state === 2 && <Two />}
      {state === 3 && <Three />}
    </Container>
  );
};

export default Com;

const Image = styled.img`
  width: 98%;
  object-fit: contain;
  height: auto;
  @media only screen and (max-width: 600px) {
    width: 120%;
    height: 100%;
  }
`;

const MyRow = styled.div`
  padding: 30px;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  width: 1610px;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media only screen and (max-width: 1600px) {
    width: 1200px;
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`;

const Container = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 860px) {
    display: block;
  }
`;
