import styled from "styled-components";
import GoogleMapReact from "google-map-react";

const AboutUs = (props) => {
  return (
    <Container>
      <div className="container">
        <div classNameName="row">
          {props.Language === "LA" && (
            <>
              <p className="fw-bold">ແນວທາງຂອງອົງກອນ</p>
              <p>
                ຍ້ອນພວກເຮົາເຂົ້າໃຈວ່າເຮືອນເປັນສູນລວມຂອງຄອບຄົວ,ເປັນບ່ອນພັກຜ່ອນ
                ແລະ ໃຊ້ຊີວິດປະຈຳ ‘ພວກເຮົາຈຶ່ງມຸ່ງເນັ້ນວັດສະດຸທີ່ປອດໄພ ແລະ
                ເປັນມິດກັບສິ່ງແວດລ້ອມ
              </p>
              <img className="img-fluid w-50" src="/c.jpg" />
              <Col1>
                <p className="mt-3">
                  ດ້ວຍການແນມເບິ່ງເຫັນເຖິງປັດໄຈ ແລະ
                  ຫົວໃຈສຳຄັນທີ່ເນັ້ນວັດສະດຸທີ່ປອດໄພ ແລະ ເປັນມິດກັບສິ່ງແວດລ້ອມ
                  ພ້ອມທັງແກ້ປັນຫາເລື່ອງຄຸນນະພາບຂອງວັດສະດຸໃຫ້ໄດ້ມາດຕະຖານ
                  ດັ່ງນັ້ນ,
                  ພວກເຮົາຈຶ່ງມຸ່ງເນັ້ນສ້າງແບຣນວັດສະດຸຕົບແຕ່ງຂຶ້ນມາທີ່ເນັ້ນຄວາມປອດໄພ
                  ເພື່ອໃຫ້ຜູ້ຊົມໃຊ້ມີຄວາມໝັ້ນໃຈໃນທຸກສິນຄ້າພາຍໃຕ້ແບລນ XBL.
                  ພວກເຮົາເນັ້ນການຕົບແຕ່ງ ແລະ
                  ສະໜອງວັດສະດຸໃຫ້ກັບໂຄງການຂະໜາດນ້ອຍ-ໃຫຍ່,ນາຍຊ່າງ ແລະ
                  ບຸກຄົນທົ່ວໄປດ້ວຍລາຄາທີ່ເໝາະສົມເພື່ອໃຫ້ສາມາດເຂົ້າເຖິງຜູ້ບໍລິໂພກທີ່ມັກການຕົບແຕ່ງ.
                </p>
                <img src="/family.jpg" className="img-fluid mb-3" />
              </Col1>

              <p>
                ທ່ານ ລີໄຊ ລາວໂຫລ ປະທານບໍລິຫານຜູ້ກໍ່ຕັ້ງບໍລິສັດ XBL
                ຈຶ່ງໄດ້ກໍ່ຕັ້ງບໍລິສັດຂຶ້ນໃນປີ 2019 ເຊິ່ງເອີ້ນວ່າ ບໍລິສັດ ລົງທຶນ
                ຈົງຈີງ ຈຳກັດຜູ້ດຽວ) ແລະ ໄດ້ປ່ຽນຊື່ມາເປັນບໍລິສັດ ເອັສບີແອວ
                 ຈຳກັດຜູ້ດຽວ. ເພື່ອເປັນຊື່ສາກົນ ແລະ
                ສອດຄ່ອງກັບການຂະຫຍາຍດຳເນີນທຸລະກິດໃຫ້ສາມາດເຂົ້າເຖິງຜູ້ຊົມໃຊ້ທົ່ວປະເທດລາວ.
              </p>

              <p>
                ບໍລິສັດໄດ້ພັດທະນາ ແລະ ເຕີບໂຕພາຍໃຕ້ແບລນ “ XBL” ມາໃນຫຼາຍປີຜ່ານມາ.
                ພວກເຮົາຍັງຈະຕ້ອງພັດທະນາໃນດ້ານການສະໜອງວັດສະດຸໃຫ້ຫຼາກຫຼາຍຂຶ້ນ ແລະ
                ຂະຫຍາຍທຸລະກິດໃຫ້ໄປທົ່ວປະເທດເພື່ອອຳນວຍຄວາມສະດວກໃຫ້ກັບຜູ້ຊົມໃຊ້
                ພ້ອມຍັງຈະມີແຜນລວມໄປເຖິງອຳນວຍຄວາມສະດວກໃນດ້ານການບໍລິການ,ດ້ານການອອກແບບ,
                ການກໍ່ສ້າງ ແລະ
                ທີມງານດ້ານເຕັກນິກເພື່ອກຽມຄວາມພ້ອມຕາມຄວາມຕ້ອງການແບບຄົບຈົບບ່ອນດຽວໃຫ້ລູກຄ້າ.
              </p>
              <p class="fw-bold">ວິໄສທັດ ແລະ ພັນທະກິດ</p>
              <Col>
                <div className="">
                  <p>➤ ຮຽນຮູ້ ແລະ ພັດທະນາເພື່ອສ້າງສິ່ງໃໝ່</p>
                  <p>➤ ມຸ່ງເນັ້ນວັດສະດຸທີ່ໄດ້ມາດຕະຖານ</p>
                  <p>➤ ມີແນວຄິດສ້າງສັນ</p>
                  <p>➤ ບໍລິການແບບມືອາຊີບ</p>
                  <p>➤ ຊື່ສັດຈິງໃຈກັບລູກຄ້າ</p>
                  <p>➤ ມີສ່ວນຮ່ວມພັດທະນາສັງຄົມ</p>
                </div>
                <div>
                  <img className="img-fluid mt-4 w-25" src="/light.png" />
                  <img className="img-fluid mt-4 w-25" src="/currency.png" />
                  <img className="img-fluid mt-4 w-25" src="/masterpiece.png" />
                  <img className="img-fluid mt-4 w-25" src="/hand.png" />
                </div>
              </Col>
            </>
          )}
          {props.Language === "EN" && (
            <>
              <p className="fw-bold">About the company</p>
              <p>
                Because we understand that the house is the center of the
                family, a place of rest and daily life, “we focus on safe and
                environmentally friendly materials”
              </p>
              <img className="img-fluid w-50" src="/c.jpg" />
              <Col1>
                <p className="mt-3">
                  By seeing the important factors and hearts that focus on safe
                  and environmentally friendly materials as well as solving the
                  problem of the quality of the materials to meet the standard.
                  Therefore, we focus on creating a decorative materials brand
                  that focuses on safety so that users can have confidence in
                  all products under the XBL brand.
                </p>
                <img src="/family.jpg" className="m-3 img-fluid mb-3 mx-5" />
              </Col1>

              <p>
                Therefore, Mr LYXAY LAOLO, the company’s president who founder
                XBL and registered the company in 2019 as Zhongjin Investment
                Sole Co,. Ltd. and Changed its name to XBL Sole Co,.
                Ltd in 2022. To be consistent and to support the expansion and
                growth of company in business operation across the country.
              </p>
              <p>
                The company has developed and grown under the "XBL" brand over
                the years. We will also have to develop in the field of
                providing more diverse materials and expand our business to the
                whole country to create and facilitate for the users and also
                have a plan to facilitate the service, design, construction and
                technical team to prepare according to the needs of a one-stop
                shop for customers.
              </p>
              <p className="fw-bold">Our core value “XBL” Way</p>
              <Col>
                <div className="col">
                  <p>➤The learning side to create new things</p>
                  <p>➤ With a creative mind</p>
                  <p>➤ With professional service</p>
                  <p>➤ Be honest with customers</p>
                  <p>➤ Participate in social development</p>
                </div>
                <div className="col">
                  <img className="imfluid mt-4 w-25" src="/light.png" />
                  <img className="img-fluid mt-4 w-25" src="/currency.png" />
                  <img className="img-fluid mt-4 w-25" src="/masterpiece.png" />
                  <img className="img-fluid mt-4 w-25" src="/hand.png" />
                </div>
              </Col>
            </>
          )}
          <ImageContainer4>
            <img className="img-fluid   h-100 p-0" src="/building.jpg" />
            <img className="img-fluid  h-100" src="/building/2.jpg" />
            <img className="img-fluid   p-0" src="/building/3.jpg" />
            <img className="img-fluid   p-0" src="/building/4.jpg" />
          </ImageContainer4>
          <ImageContainer3>
            <img className="img-fluid  h-100 p-0" src="/building/5.jpg" />
            <img className="img-fluid  h-100 p-0" src="/building/6.jpg" />
            <img className="img-fluid  h-100  p-0" src="/building/7.jpg" />
          </ImageContainer3>
          <ImageContainer3>
            <img className="img-fluid  h-100 p-0" src="/building/8.jpg" />
            <img className="img-fluid  h-100 p-0" src="/building/9.jpg" />
            <img className="img-fluid  h-100  p-0" src="/building/10.jpg" />
          </ImageContainer3>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 100px;
  padding-bottom: 50px;
  padding-top: 80px;
  background-color: white;
`;

const ImageContainer3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
`;

const ImageContainer4 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
`;

const Col = styled.div`
  display: grid;
  gap: 20px;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  grid-auto-rows: minmax(100px, auto);
  @media only screen and (max-width: 860px) {
    flex-direction: column;
    display: flex;
    align-content: center;
  }
`;

const Col1 = styled.div`
  display: grid;
  gap: 20px;
  align-content: center;
  align-items: center;
  grid-template-columns: 3fr 3fr;
  grid-auto-rows: minmax(100px, auto);
  @media only screen and (max-width: 860px) {
    flex-direction: column;
    display: flex;
    align-content: center;
  }
`;

export default AboutUs;
