import Product from "../Product";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import BasedUrl from "../../constrants";

function ProductsDelete() {
  const [productsList, setProductList] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/products/getAll").then((res) => {
        setProductList(res.data);
        setTrigger(false);
      });
    } catch (error) {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ");
    }
  }, [trigger]);

  const deleteProduct = (uuid) => {
    const Uuid = {
      uuid: uuid,
    };
    axios
      .delete(BasedUrl + "/products/deleteOne", { data: Uuid })
      .then((res) => {
        console.log(res.data);
        alert("ລົບສິນຄ້າສຳເລັດ");
      })
      .catch((error) => {
        alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ: \n" + error);
      });
  };
  return (
    <Container>
      <div className="text-center mb-5">
        <button
          onClick={() => setTrigger(true)}
          className="btn btn-primary mb-3"
        >
          ໂຫລດໃໝ່
        </button>
      </div>
      <Row>
        {productsList.map((product) => (
          <P key={product.uuid}>
            <Product
              key={product.uuid}
              uuid={product.uuid}
              ProductName={product.p_name}
              ImageUrl={product.img1}
              Size={product.size}
            />
            <div className="text-center">
              <button 
                onClick={() => {
                  if (
                    window.confirm("ທ່ານຕ້ອງການລົບສິນຄ້ານີ້ແທ້ບໍ່?") === true
                  ) {
                    deleteProduct(product.uuid);
                    setTrigger(true);
                  }
                }}
                className="btn btn-danger w-50 mb-5"
              >
                ລົບ
              </button>
            </div>
          </P>
        ))}
      </Row>
    </Container>
  );
}

export default ProductsDelete;

const Container = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 100px;
  padding: 20px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  overflow-y: scroll;
  width: 100%;
`;
const P = styled.div`
   &:hover {
    background-color: #eeeeee;
  }
`;
