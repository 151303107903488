import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../../constrants";

function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

const ProductAdmin = () => {
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");
  const [img5, setImg5] = useState("");

  const [name, setName] = useState("");
  const [colorID, setColorID] = useState("");
  const [color, setColor] = useState("");
  const [colorE, setColorE] = useState("");

  const [size, setSize] = useState("");
  const [properties, setProperties] = useState("");
  const [madeFrom, setMadeFrom] = useState("");
  const [using, setUsing] = useState("");

  const [nameE, setNameE] = useState("");
  const [propertiesE, setPropertiesE] = useState("");
  const [madeFromE, setMadeFromE] = useState("");
  const [usingE, setUsingE] = useState("");

  const [uimg1, setUsingImg1] = useState("");
  const [product, setProduct] = useState({});
  const [suggest, setSuggest] = useState(false);

  const [setting, setSetting] = useState("");
  const [settingE, setSettingE] = useState("");

  const AddProduct = (product) => {
    try {
      axios.post(BasedUrl + "/products/add", product).then((res) => {
        alert("ເພີ່ມສິນຄ້າສຳເລັດ");
      });
    } catch (error) {
      alert("ເພີ່ມສິນຄ້າບໍ່ສຳເລັດ: \n" + error);
    }
  };
  const OnClick = () => {
    setProduct({
      name: name,
      color_id: colorID,
      color: color,
      colorE,
      properties: properties,
      made_from: madeFrom,
      using: using,
      size: size,
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4,
      img5: img5,
      uimg1: uimg1,
      suggest: suggest,
      nameE: nameE,
      propertiesE,
      madeFromE,
      usingE
    });
  };

  useEffect(() => {
    if (isEmptyObject(product)) {
    } else {
      AddProduct(product);
    }
  }, [product]);

  return (
    <Container>
      <div className="row">
        <div className="col">
          <input
            onChange={(e) => setImg1(e.target.value)}
            className="form-control mb-3 text-center"
            placeholder="ລິງຮູບພາບ"
            value={img1}
          />
          <Image className="img-fluid rounded" src={img1} />
        </div>
        <div className="col">
          <input
            onChange={(e) => setImg2(e.target.value)}
            className="form-control mb-3 text-center"
            placeholder="ລິງຮູບພາບ"
            value={img2}
          />
          <Image className="img-fluid rounded" src={img2} />
        </div>
        <div className="col">
          <input
            onChange={(e) => setImg3(e.target.value)}
            className="form-control mb-3 text-center"
            placeholder="ລິງຮູບພາບ"
            value={img3}
          />
          <Image className="img-fluid rounded" src={img3} />
        </div>
        <div className="col">
          <input
            onChange={(e) => setImg4(e.target.value)}
            className="form-control mb-3 text-center"
            placeholder="ລິງຮູບພາບ"
            value={img4}
          />
          <Image className="img-fluid rounded" src={img4} />
        </div>
        <div className="col">
          <input
            onChange={(e) => setImg5(e.target.value)}
            className="form-control mb-3 text-center"
            placeholder="ລິງຮູບພາບ"
            value={img5}
          />
          <Image className="img-fluid rounded" src={img5} />
        </div>
      </div>
      <Row>
        <Column>
          <input
            onChange={(e) => setName(e.target.value)}
            className="form-control mb-3 w-50 text-center"
            placeholder="ຊື່ສິນຄ້າ"
            value={name}
          />
          <input
            onChange={(e) => setColor(e.target.value)}
            className="form-control mb-3 w-50 text-center"
            placeholder="ສີ"
            value={color}
          />
          <input
            onChange={(e) => setColorE(e.target.value)}
            className="form-control mb-3 w-50 text-center"
            placeholder="Color"
            value={colorE}
          />
          <input
            onChange={(e) => setColorID(e.target.value)}
            className="form-control mb-3 w-50 text-center"
            placeholder="ລະຫັດສີ"
            value={colorID}
          />
          <input
            onChange={(e) => setSize(e.target.value)}
            className="form-control mb-3 w-50 text-center"
            placeholder="ຂະຫນາດ"
            value={size}
          />
        </Column>
      </Row>
      <Row>
        <input
          onChange={(e) => setNameE(e.target.value)}
          className="form-control mb-3 w-50 text-center"
          placeholder="product name"
          value={nameE}
        />
        <img className="img-fluid" src={properties} />
        <input
          onChange={(e) => setProperties(e.target.value)}
          className="form-control mb-3 w-50 text-center"
          rows="4"
          placeholder="ຄຸນນະສົມບັດ"
          value={properties}
        ></input>
        <img className="img-fluid" src={propertiesE} />
        <input
          onChange={(e) => setPropertiesE(e.target.value)}
          className="form-control mb-3 w-50 text-center"
          rows="4"
          placeholder="properties"
          value={propertiesE}
        ></input>
        <textarea
          onChange={(e) => setMadeFrom(e.target.value)}
          value={madeFrom}
          class="form-control text-center mb-3"
          rows="4"
          placeholder="ຜະລິດມາຈາກ"
        ></textarea>
        <textarea
          onChange={(e) => setMadeFromE(e.target.value)}
          value={madeFromE}
          class="form-control text-center mb-3"
          rows="4"
          placeholder="Made from"
        ></textarea>
        <h1>{suggest}</h1>
        <div class="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            value={suggest}
            onChange={(e) => {
              setSuggest(e.target.checked);
            }}
          />
          <label class="form-check-label" for="flexCheckDefault">
            ເພິ່ມໃນລາຍການແນະນຳສິນຄ້າ
          </label>
        </div>
      </Row>
      <Row>
        <h3>ຮູບພາບການນຳໃຊ້</h3>
        <div className="row">
          <div className="col">
            <input
              onChange={(e) => setUsingImg1(e.target.value)}
              className="form-control mb-3 text-center"
              placeholder="ລິງຮູບພາບ"
              value={uimg1}
            />
            <Image className="img-fluid rounded" src={uimg1} />
          </div>
        </div>
        <textarea
          onChange={(e) => setUsing(e.target.value)}
          value={using}
          class="form-control text-center mb-3"
          rows="4"
          placeholder="ວິທີການນຳໃຊ້"
        ></textarea>
        <textarea
          onChange={(e) => setUsingE(e.target.value)}
          value={usingE}
          class="form-control text-center mb-3"
          rows="4"
          placeholder="Using"
        ></textarea>
      </Row>
      <h1>{suggest}</h1>
      <Row>
        <button onClick={OnClick} className="btn btn-primary w-50">
          ເພິ່ມ
        </button>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: white;
  padding: 20px;
`;

const Row = styled.div`
  gap: 10px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
const Column = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
`;

const Image = styled.img`
  width: 200px;
  height: 150px;
`;

export default ProductAdmin;
