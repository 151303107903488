import styled from "styled-components";
import Post from "./Post";
import Videos from "./Videos";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasedUrl from "../constrants";
import PostDetail from "./PostDetail";

const SideBar = () => {
  const [posts, setPosts] = useState([]);
  const [postUuid, setPostUuid] = useState("");
  const [postDetailVisible, setPostDetailVisible] = useState(false);

  const navigate = useNavigate();

  const PostDetailClose = () => {
    setPostDetailVisible(false);
  };
  useEffect(() => {
    try {
      axios.get(BasedUrl + "/posts/getAll").then((res) => {
        setPosts(res.data);
      });
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <Container>
      <Posts>
        {posts.map((post) => (
          <div
            key={post.uuid}
            onClick={() => {
              setPostUuid(post.uuid);
              setPostDetailVisible(true);
              navigate("/postdt", { state: post });
            }}
          >
            <Post
              PostData={posts}
              Topic={post.topic}
              ImageUrl={post.img}
              deleted="no"
            />
          </div>
        ))}
      </Posts>
      <Videos />
    </Container>
  );
};

export default SideBar;
const Posts = styled.div`
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 7px;
  max-width: 300px;
  height: 200px;
  align-items: start;
  justify-content: start;
  overflow-x: scroll;
  overflow-x: hidden;
  margin-bottom: 7px;
  padding-left: 12px;
  @media only screen and (max-width: 999px) {
    flex-direction: row;
    gap: 10px;
    overflow-y: scroll;
    max-width: 1000px;
    height: auto;
    margin-right: 0px;
    overflow-x: scroll;
    margin-bottom: 7px;
    margin-top: 7px;
    width: 100%;
  }
`;

const Container = styled.div``;
