import { useEffect, useState } from "react";
import styled from "styled-components";
import ProductAdmin from "../components/admin/product.admin";
import ProductsDelete from "../components/admin/product,delete";
import PostAdmin from "../components/admin/post.admin";
import PostDelete from "../components/admin/post.delete";
import VideosAdmin from "../components/admin/videos.admin";
import BannersAdmin from "../components/admin/banners.admin";
import MasterpieceAdmin from "../components/admin/masterpiece.admin";
import BasedUrl from "../constrants";
import axios from "axios";
import CatalogesAdmin from "../components/admin/Cataloges.admin";
import InstallAdmin from "../components/admin/install.admin";

const Admin = () => {
  const handleClick = () => {
    setPassword1(password);
  };

  const AdminNavbar = () => {
    return (
      <Column>
        <button
          onClick={() => setSelectedMenu("1")}
          className="btn btn-primary"
        >
          ສິນຄ້າ
        </button>
        <button
          onClick={() => setSelectedMenu("2")}
          className="btn btn-primary"
        >
          ບົດຄວາມ
        </button>
        <button
          onClick={() => setSelectedMenu("3")}
          className="btn btn-primary"
        >
          ວິດີໂອ
        </button>
        <button
          onClick={() => setSelectedMenu("4")}
          className="btn btn-primary"
        >
          ແບນເນີ້
        </button>
        <button
          onClick={() => setSelectedMenu("5")}
          className="btn btn-primary"
        >
          ຜົນງານ
        </button>
        <button
          onClick={() => setSelectedMenu("6")}
          className="btn btn-primary"
        >
          ແຄັດຕາລ໋ອກ
        </button>
        <button
          onClick={() => setSelectedMenu("7")}
          className="btn btn-primary"
        >
          ການຕິດຕັ້ງ
        </button>
      </Column>
    );
  };
  const [selectedMenu, setSelectedMenu] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [visitors, setVisitors] = useState();

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    axios.get(BasedUrl + "/visitors/get").then((rs) => {
      setVisitors(rs.data[0].count);
    });
  });

  // const [imaage, setImage] = useState();
  // const onImageChange = (event) => {
  //   const data = new FormData();
  //   data.append("image", event.target.files[0]);
  //   axios.post("http://localhost:8000/upload", data).then((res) => {
  //     // then prin  t response status
  //     alert("Success");
  //   });

  //   // if (event.target.files && event.target.files[0]) {
  //   //   setImage(URL.createObjectURL(event.target.files[0]));
  //   // }
  // };

  // const [binary, setBimary] = useState();

  // const getPhoto = () => {
  //   axios.get("http://localhost:8000/get").then((res) => {
  //     // then prin  t response status
  //     //   setBimary(res.data);
  //     console.log(res.data);
  //     //console.log(res);
  //     // alert(res);
  //   });
  // };

  return (
    <Container>
      {/* <div>
        <button className="btn btn-primary" onClick={() => getPhoto()}>
          Get photo
        </button>
        <img src={URL.createObjectURL(bina)} />
        <img id="target" src={imaage} className="img w-100" />
        <input
          type="file"
          onChange={onImageChange}
          className="filetype"
          id="group_image"
        />
      </div> */}
      {password1 === "123" && (
        <div className="container text-center mt-3">
          <AdminNavbar />
          {selectedMenu === "1" && (
            <div>
              <ProductAdmin />
              <ProductsDelete />
            </div>
          )}
          {selectedMenu === "2" && (
            <div>
              <PostAdmin />
              <PostDelete />
            </div>
          )}
          {selectedMenu === "3" && (
            <div>
              <VideosAdmin />
            </div>
          )}
          {selectedMenu === "4" && (
            <div>
              <BannersAdmin />
            </div>
          )}
          {selectedMenu === "5" && (
            <div>
              <MasterpieceAdmin />
            </div>
          )}
          {selectedMenu === "6" && (
            <div>
              <CatalogesAdmin />
            </div>
          )}
          {selectedMenu === "7" && (
            <div>
              <InstallAdmin />
            </div>
          )}
        </div>
      )}
      {password1 !== "123" && (
        <Container>
          <input
            className="form-control text-center w-50"
            type="password"
            placeholder="ລະຫັດຜ່ານ"
            onChange={handleChange}
            value={password}
          />
          <button className="btn btn-primary mt-3" onClick={handleClick}>
            ຢືນຢັນ
          </button>
        </Container>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 50%;
`;

const Column = styled.div`
  margin-top: 20px;
  gap: 5px;
  display: flex;
  flex-dirextion: row;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export default Admin;
