import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../../constrants";

function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}
const AddPost = async (post) => {
  try {
    await axios.post(BasedUrl + "/posts/add", post).then((res) => {
      alert("ເພີ່ມບົດຄວາມສຳເລັດ");
    });
  } catch (error) {
    alert("ເກີດບັນຫາໃນການເພີ່ມ ກະລູນາລອງໃໝ່: \n" + error);
  }
};

const PostAdmin = () => {
  const [img, setImg] = useState("");
  const [info, setInfo] = useState("");
  const [topic, setTopic] = useState("");
  const [post, setPost] = useState({});
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");
  const [img5, setImg5] = useState("");
  const [img6, setImg6] = useState("");
  const [img7, setImg7] = useState("");

  const OnClick = () => {
    setPost({
      img: img,
      topic: topic,
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
    });
  };

  useEffect(() => {
    if (isEmptyObject(post)) {
    } else {
      AddPost(post);
    }
  }, [post]);

  const Images = () => (
    <Grid>
      <div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img1} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg1(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບ"
            value={img1}
          />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img2} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg2(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບ"
            value={img2}
          />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img3} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg3(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບຫລັກ"
            value={img3}
          />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img4} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg4(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບຫລັກ"
            value={img4}
          />
        </div>
      </div>{" "}
      <div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img5} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg5(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບຫລັກ"
            value={img5}
          />
        </div>
      </div>{" "}
      <div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img6} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg6(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບຫລັກ"
            value={img6}
          />
        </div>
      </div>
    </Grid>
  );

  return (
    <div className="row bg-white p-5 m-5">
      <div className="col">
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບຫລັກ"
            value={img}
          />
        </div>
        <div className="d-flex justify-content-center mb-4">
          <Image className="img-fluid rounded" src={img7} />
        </div>
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setImg7(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ລິງຮູບພາບ"
            value={img7}
          />
        </div>
        <Images />
        <div className="d-flex justify-content-center row">
          <input
            onChange={(e) => setTopic(e.target.value)}
            className="form-control mb-3 text-center w-50"
            placeholder="ຫົວຂໍ້"
            value={topic}
          />
        </div>
        <div className="d-flex justify-content-center row">
          <textarea
            onChange={(e) => setInfo(e.target.value)}
            class="form-control text-center mb-3 w-50"
            rows="4"
            placeholder="ລາຍລະອຽດ"
            value={info}
          ></textarea>
        </div>
      </div>
      <div className="d-flex justify-content-center row">
        <button onClick={OnClick} className="btn btn-primary w-50">
          ເພິ່ມ
        </button>
      </div>
    </div>
  );
};

const Image = styled.img`
  width: 200px;
  height: 150px;
`;

const Grid = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;
export default PostAdmin;
