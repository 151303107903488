import Post from "../Post";
import axios from "axios";
import BasedUrl from "../../constrants";
import { useEffect, useState } from "react";
import styled from "styled-components";

const deletePost = (uuid) => {
  const Uuid = {
    uuid: uuid,
  };
  axios
    .delete(BasedUrl + "/posts/deleteOne", { data: Uuid })
    .then((res) => {
       alert("ລົບບົດຄວາມສຳເລັດ");
    })
    .catch((error) => {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ: \n" + error);
    });
};

const PostDelete = () => {
  const [posts, setPosts] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/posts/getAll").then((res) => {
        setPosts(res.data);
        setTrigger(false);
      });
    } catch (error) {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ");
    }
  }, [trigger]);

  return (
    <div className="container mb-5 text-center">
      <h3>ລາຍກາຮບົດຄວາມທັງໝົດ</h3>
      <div>
        <button
          onClick={() => setTrigger(true)}
          className="btn btn-primary mb-3"
        >
          ໂຫລດໃໝ່
        </button>
      </div>
      <List>
        {posts.map((post) => (
          <div
            className="row"
            key={post.uuid}
            onClick={() => {
              if (window.confirm("ທ່ານຕ້ອງການລົບບົດຄວາມນີ້ແທ້ບໍ່?") == true) {
                deletePost(post.uuid);
                setTrigger(true);
              }
            }}
          >
            <div className="row">
              <Post
                ImageUrl={post.img}
                Description={post.info}
                deleted="ok"
                Topic={post.topic}
              />
            </div>
          </div>
        ))}
      </List>
    </div>
  );
};

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default PostDelete;
