import styled from "styled-components";

import { Zoom } from "react-reveal";

const Buying = (props) => {
  const E = () => {
    return (
      <di>
        <span>
          Dear, to the customers of XBL decoration and construction. We are with
          XBL Co., Ltd. The company is located at Dong Dok-Si Kert Road, NaSiew
          Village (12th Street), Nasaythong District, Vientiane Capital. The
          company supplies interior and exterior decoration materials for
          construction projects, craftsmen and groups of customers and house
          building. Our decorative materials are artificial wood in various
          types such as: artificial wood, baseboard, and wood slats, exterior
          decoration materials and SPC floor tiles. We also offer interior and
          exterior decoration services.
        </span>
        <div className="container mt-3 ">
          <h5> ▶ Company policies and requirements</h5>
          <ui className="row">
            <span>
              - When a customer enters and buys a product on our website
              www.xbl.la, it indicates that the customer has agreed to the terms
              of the website.
            </span>
            <span>
              - Our website may contain updates, modifications and changes
              regarding pricing information and company policies suddenly
              automatically without prior notice to the customer.
            </span>
            <span>
              - Therefore, when the customer uses the website, it means that the
              customer understands and agrees with the terms and conditions.
              Consequently, we require the customer to check and review the
              terms in detail before ordering any product.
            </span>
          </ui>

          <h5 className="mt-2">
            ▶ About the processing time and ordering the product
          </h5>
          <ui className="row">
            <span>
              <ui className="row">
                <span>
                  -Customers can access the website snd order products at
                  anytime
                </span>
                <span>
                  - All prooducts are ordered after 17:00 of theday, the order
                  will be proccessed and confirmed the ordering on the next day,
                  including the products that in the promotion time
                </span>
                <span>
                  - All products ordered on Sundays and during various festivals
                  are temporarily closed will bbe resolved whenthe company
                  operates the normally
                </span>
              </ui>
            </span>
            <span>
              - The quantity of all products on the website may be less than the
              actual quantity that has been announced because of the quantity
              update product listings may be incorrect and delayed.
            </span>
          </ui>

          <h5 className="mt-2">
            ▶ The Information about products, prices and promotions
          </h5>
          <ui className="row">
            <ui className="row">
              <span>
                - The information on all types of products on the website has
                been posted and verified
              </span>
            </ui>
            <ui className="row">
              <span>
                - The images of the products on the website are taken directly
                from actual products, but the colors might be different because
                of the quality of the equipment in photography
              </span>
            </ui>
            <ui className="row">
              <span>
                - All promotions and price reductions on the website are
                authorized and agreed by the company, which may result in
                products being sold in person and website may differ.
              </span>
            </ui>
            <ui className="row">
              <span>
                - The quantity of all products on the website may be less than
                the actual quantity that has been announced because of the
                quantity update product listings may be incorrect and delayed.
              </span>
            </ui>
          </ui>

          <h5 className="mt-2">▶ About the price of materials</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - Product prices may not be announced on the website, customers
                can call or contact us through the company's various contact
                channels to get more details about the products.
              </span>
            </ui>
          </ui>

          <h5 className="mt-2">▶ ordering products online</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - Every time before buying a product, the customer must check
                the type, size, number, color, feature, code clearly and
                Correctly before placing an order.
              </span>
              <span>
                <span>
                  - Every time before buying a product, the customer must check
                  the type, size, number, color, feature, code clearly and
                  Correctly before placing an order.
                </span>
              </span>
            </ui>
          </ui>

          <h5 className="mt-2">▶ About the price and materials</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - Products prices may not be announced on the website, customers
                can call or contact us through the company's various contact
                channels to get more details about the products
              </span>
            </ui>
          </ui>
          <h5 className="mt-2">▶ Ordering products online</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - Every time before buying a product, the customer must check
                the type, size, number, color, feature, code clearly and
                Correctly before placing an order
              </span>
            </ui>
            <ui className="row">
              <span>
                - When ordering products online through the website, customers
                must type the information correctly and clearly about: name and
                surname, address, phone number, e-mail... so that the company's
                team can easily contact and arrange the order for the customer
                on time
              </span>
            </ui>
          </ui>
          <h5 className="mt-2">▶ Preparing and shopping</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - The preparation and delivery of the customer's products is
                based on the time ordered, whether the customer is near or far,
                the delivery will take 1-3 days
                <div className="row ms-5">
                  <span>
                    ● For customers in the capital: When the customer has
                    ordered the product and do the payment, the company delivery
                    team will arrange orders for customers (free delivery for
                    customers in the capital)
                  </span>
                  <span>
                    ● For customers from other provinces: When the customer has
                    ordered the product and do the payment, The delivery fee
                    will be notified to the customer immediately after the
                    shipping company has issued the order to receive the product
                    (the delivery fee depends on the quantity, weight and size
                    of the material) after the customer receives the product,
                    require the customer notifies to the company
                  </span>
                </div>
              </span>
            </ui>
          </ui>
        </div>
        <div className="text-center">
          <h5 className="mt-3">Thank you so much for supporting us</h5>
        </div>
        <div className="container mt-3 ">
          <h5> ▶ Contact information directly with the company</h5>
          <ui className="row">
            <span>
              - - Customers who have doubts can inquire about the details of the
              product can come and discuss with us at XBL Company 
              Limited. Located on Dong Dok-SiKert Road, NaSiew Village (12th
              Street), Nasaithong District, Vientiane capital or via 030 5579
              777, 020 5789 5999, 020 7676 6969. E-mail: xbl.dve@gmail.com
            </span>
          </ui>
        </div>
      </di>
    );
  };

  const LA = () => {
    return (
      <di>
        <span>
          ສະບາຍດີບັນດາລູກຄ້າຂອງ ເອັສບີແອວ ຕົບແຕ່ງ ແລະ ກໍ່ສ້າງ.
          ພວກເຮົາແມ່ນຂຶ້ນກັບບໍລິສັດ ເອັສບີແອວ ຈຳກັດຜູ້ດຽວ. ບໍລິສັດຕັ້ງຢູ່:ຖະໜົນ
          ດົງໂດກ-ສີເກີດ, ບ້ານນາສ້ຽວ ( ໜ້າຮ່ອມ 12 ), ເມືອງນາຊາຍທອງ,
          ນະຄອນຫຼວງວຽງຈັນ. ບໍລິສັດຂອງພວກເຮົາສະໜອງວັດສະດຸ ແລະ ຕົບແຕ່ງພາຍໃນ ແລະ
          ພາຍນອກໃຫ້ແກ່ໂຄງການ, ນາຍຊ່າງ ແລະ ລູກຄ້າກຸ່ມຕ່າງໆ ແລະ
          ກໍ່ສ້າງອາຄານບ້ານເຮືອນ.
          ວັດສະດຸຕົບແຕ່ງຂອງພວກເຮົາແມ່ນໄມ້ທຽມມີຫຼາກຫຼາຍຮູບແບບ: ແຜ່ນໄມ້ທຽມ,
          ໄມ້ບົວ, ໄມ້ລະແນງ, ວັດສະດຸຕົບແຕ່ງດ້ານນອກ ແລະ ກະໂລ້ປູພື້ນ SPC.
          ພວກເຮົາຍັງມີບໍລິການຕົບແຕ່ງພາຍໃນ ແລະ ພາຍນອກ.
        </span>
        <div className="container mt-3 ">
          <h5> ▶ ນະໂຍບາຍ ແລະ ຂໍ້ກຳນົດຂອງບໍລິສັດ</h5>
          <ui className="row">
            <span>
              - ເມື່ອລູກຄ້າເຂົ້າ ແລະ ຊື້ສິນຄ້າໃນເວັບໄຊທ໌໌ www.xbl.la
              ຂອງພວກເຮົາສະແດງວ່າລູກຄ້າໄດ້ເຫັນດີກັບບັນດຂໍ້າກຳນົດຂອງເວັບໄຊທ໌.
            </span>
            <span>
              - ດັ່ງນັ້ນ, ເມື່ອລູກຄ້າເຂົ້າໃຊ້ເວັບໄຊທ໌ສະແດງວ່າລູກຄ້າເຂົ້າໃຈ ແລະ
              ເຫັນດີກັບຂໍ້ກຳນົດ ສະນັ້ນ, ຂໍໃຫ້ລູກຄ້າກວດກາ ແລະ
              ເບິ່ງຂໍ້ມູນໃຫ້ລະອຽດກ່ອນຈະມີການສັ່ງຊື້ສິນຄ້າທຸກຄັ້ງ.
            </span>
          </ui>

          <h5 className="mt-2">▶ ກ່ຽວກັບເວລາການແກ້ໄຂ ແລະ ສັ່ງຊື້ສິນຄ້າ</h5>
          <ui className="row">
            <span>
              <ui className="row">
                <span>
                  - ລູກຄ້າສາມາດເຂົ້າເວັບໄຊທ໌ ແລະ
                  ສັ່ງຊື້ສິນຄ້າໄດ້ທຸກເວລາທີ່ຕ້ອງການ
                </span>
                <span>
                  - ທຸກລາຍການສັ່ງຊື້ສິນຄ້າຫຼັງຈາກ 17:00
                  ຂອງມື້ທີ່ສັ່ງຊື້ສິນຄ້າຈະໄດ້ຮັບການແກ້ໄຂ ແລະ
                  ຮັບການຢືນຢັ້ນການສັ່ງຊື້ພາຍໃນຂອງມື້ອື່ນທັນທີລວມທັງລາຍການທີ່ມີໂປຣໂມຊັ່ນໃນຊ່ວງວັນ
                  ແລະ ເວລາທີ່ລູກຄ້າສັ່ງຊື້ນັ້ນ.
                </span>
                <span>
                  -ທຸກລາຍການທີ່ລູກຄ້າສັ່ງຊື້ສິນຄ້າໃນວັນອາທິດ ແລະ
                  ຊ່ວງງານເທດສະການງານບຸນຕ່າງໆທີ່ບໍລິສັດຢຸດການດຳເນີນການຊົ່ວຄາວຈະໄດ້ຮັບການແກ້ໄຂໃນການເຄື່ອນທີ່ບໍລິສັດກັບມາດຳເນີນການຕາມປົກກະຕິ.
                </span>
              </ui>
            </span>
          </ui>

          <h5 className="mt-2">▶ ຂໍ້ມູນກ່ຽວກັບສິນຄ້າ, ລາຄາ ແລະ ໂປຣໂມຊັ່ນ</h5>
          <ui className="row">
            <ui className="row">
              <span>
                -ຂໍ້ມູນຕ່າງໆຂອງສິນຄ້າທຸກປະເພດເທິງເວັບໄຊທ໌ໄດ້ຮັບການລົງ ແລະ
                ຢັ້ງຢືນຕົວຈິງ
              </span>
              <span>
                -
                ຮູບພາບປະກອບຂອງສິນຄ້າເທິງເວັບໄຊທ໌ແມ່ນຖືກຖ່າຍຈາກຮູບຂອງສິນຄ້າຕົວຈິງໂດຍກົງແຕ່ອາດຈະມີສີທີ່ຕ່າງຈາກສິນຄ້າຕົວຈິງຍ້ອນການຄຸນນະພາບຂອງອຸປະກອນໃນການຖ່າຍພາບ.
              </span>
              <span>
                - ທຸກໆໂປຣໂມຊັ່ນ,ການຫຼຸດລາຄາເທິງເວັບໄຊທ໌ແມ່ນໄດ້ຮັບຄຳສັ່ງ ແລະ
                ຕົກລົງເຫັນດີຈາກບໍລິສັດ, ອາດເຮັດໃຫ້ສິນຄ້າທີ່ຂາຍຕົວຈິງໃນໜ້າບໍລິສັດ
                ແລະ ເທິງເວັບໄຊທ໌ອາດມີຄວາມແຕກຕ່າງກັນ.
              </span>
              <span>
                <span>
                  -
                  ຈຳນວນສິນຄ້າທຸກລາຍການເທິງເວັບໄຊທ໌ອາດຈະມີຈຳນວນນ້ອຍກວ່າບໍ່ຕົງຕາມຕົວຈິງທີ່ໄດ້ແຈ້ງ
                  ເພາະການອັບເດດຈຳນວນລາຍການສິນຄ້າອາດຜິດພາດ ແລະ ຊັກຊ້າ.
                </span>
              </span>
            </ui>
          </ui>

          <h5 className="mt-2">▶ ກ່ຽວກັບລາຄາຂອງວັດສະດຸ</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - ລາຄາສິນຄ້າອາດບໍ່ໄດ້ແຈ້ງເທິງເວັບໄຊທ໌, ລູກຄ້າສາມາດໂທ ຫຼື
                ຕິດຕໍ່ກັບພວກເຮົາໄດ້ຕາມຊ່ອງທາງການຕິດຕໍ່ຕ່າງໆຂອງບໍລິສັດ
                ເພື່ອຈະໄດ້ຮັບຂໍ້ມູນທັນທີ່ຈາກພວກເຮົາ.
              </span>
            </ui>
          </ui>

          <h5 className="mt-2">▶ ການສັ່ງຊື້ສິນຄ້າທາງອອນລາຍ</h5>
          <ui className="row">
            <ui className="row">
              <span>
                -
                ທຸກຄັ້ງກ່ອນທີ່ຈະມີການຊື້ສິນຄ້າແມ່ນໃຫ້ລູກຄ້າກວດກາປະເພດ,ຂະໜາດ,ຈຳນວນ,
                ສີ, ລາຍ ແລະ ລະຫັດໃຫ້ຊັດເຈນ ແລະ
                ຖືກຕ້ອງກ່ອນເຮັດການສັ່ງຊື້ກັບພວກເຣົາ.
              </span>
            </ui>
          </ui>

          <h5 className="mt-2">▶ ການການກະກຽມ ແລະ ຈັດສົ່ງສິນຄ້າ</h5>
          <ui className="row">
            <ui className="row">
              <span>
                - ການກະກຽມ ແລະ
                ຈັດສົ່ງລາຍການບິນຈັດຊື້ຂອງລູກຄ້າແຕ່ລະບິນແມ່ນຈະອີງຕາມລຳດັບຂອງການຈັດຊື້ບໍ່ວ່າລູກຄ້າຈະຢູ່ໃກ້
                ຫຼື ຢູ່ໄກການຈັດສົ່ງອາດຈະແມ່ນ 1-3 ມື້.
                <div className="row ms-5">
                  <span>
                    ● ສຳລັບລູກຄ້າພາຍໃນນະຄອນຫຼວງ:
                    ເມື່ອລູກຄ້າສັ່ງຊື້ສິນຄ້າແລ້ວເສັດສົມບູນ ແລະ
                    ມີການຊຳລະລາຍການຕາມບິນສັ່ງຊື້ແລ້ວ,
                    ທີມພະແນກຈັດສົ່ງຂອງບໍລິສັດຈະມີການຈັດສົ່ງໃຫ້ລູກຄ້າຕາມລຳດັບ (
                    ສົ່ງຟຣີສຳລັບລູກຄ້າພາຍໃນໃຈກາງຕົວເມືອງນະຄອນຫຼວງ ).
                  </span>
                  <span>
                    ● ສຳລັບລູກຄ້າຕ່າງແຂວງ:
                    ເມື່ອລູກຄ້າສັ່ງຊື້ສິນຄ້າແລ້ວເສັດສົມບູນ ແລະ
                    ມີການຊຳລະລາຍການຕາມບິນສັ່ງຊື້,
                    ກ່ຽວກັບຄ່າຈັດສົ່ງແມ່ນຈະໄດ້ມີການແຈ້ງກັບໃຫ້ລູກຄ້າທັນທີພາຍຫຼັງທີ່ບໍລິສັດຂົນສົ່ງໄດ້ອອກບິນຮັບສົ່ງສິນຄ້າ
                    ( ຄ່າຈັດສົ່ງແມ່ນຂຶ້ນຢູ່ກັບຈຳນວນ, ນ້ຳໜັກ ແລະ ຂະໜາດຂອງວັດສະດຸ
                    )
                    ຫຼັງຈາກລູກຄ້າໄດ້ຮັບສິນຄ້າແລ້ວລົບກວນລູກຄ້າແຈ້ງກັບໃຫ້ບໍລິສັດໄດ້ຮັບຮູ້
                  </span>
                </div>
              </span>
            </ui>
          </ui>
        </div>
        <div className="text-center">
          <h5 className="mt-3">ຂໍຂອບໃຈທີ່ໃຫ້ການອຸດໜູນ</h5>
        </div>
        <div className="container mt-3 pb-5">
          <h5> ▶ ຂໍ້ມູນຕິດຕໍ່ໂດຍກົງກັບບໍລິສັດ</h5>
          <ui className="row">
            <span>
              -
              ລູກຄ້າມີຂໍ້ສົງໃສສາມາດສອບຖາມກ່ຽວກັບລາຍລະອຽດຂອງສິນຄ້າແມ່ນສາມາດເຂົ້າມາພົວພັນກັບພວກເຮົາໄດ້ທີ່ບໍລິສັດ
              ເອັສບີແອວ ຈຳກັດຜູ້ດຽວ. ຕັ້ງຢູ່ຖະໜົນດົງໂດກ-ສີເກີດ, ບ້ານນາສ້ຽວ (
              ໜ້າຮ່ອມ 12 ), ເມືອງນາຊາຍທອງ, ນະຄອນຫຼວງວຽງຈັນ ຫຼື ຜ່ານ 030 5579
              777, 020 5789 5999, 020 7676 6969. E-mail: xbl.dve@gmail.com.
            </span>
          </ui>
        </div>
      </di>
    );
  };

  return (
    <Container>
      <Zoom>
        <div className="container">{props.Language === "EN" && <E />}</div>
        <div className="container">{props.Language === "LA" && <LA />}</div>
      </Zoom>
    </Container>
  );
};

const Container = styled.div`
    background-color white;
    width: 1000%
    height: 100%;
    margin-top: 100px;
    padding-top: 80px;
    padding-bottom: 20px;
 `;

export default Buying;
