import styled from "styled-components";
const One = () => {
  return (
    <Pa>
      <Container>
        <div class="container">
          <span className="bl  px-4 py-2">ແຜ່ນໄມ້ທຽມມັກນຳໃຊ້ຕົບແຕ່ງໂຊນໃດ</span>
          <div class="row mt-5 mb-3 d-flex align-items-center text-start ">
            <span className="bb">
              ກ່ອນອຶ່ນຕ້ອງມາທຳຄວາມຣູ້ຈັກກັບວັດສະດຸປະເພດນີ້ກ່ອນວ່າເປັນເເນວໃດ?
            </span>
          </div>
          <div class="row">
            <span>
              ໂດຍທົ່ວໄປໄມ້ທຽມນັ້ນມີຫລາຍປະເພດທີ່ແຕກຕ່າງກັນໄປຕາມອັດຕາສ່ວນປະກອບ ແລະ
              ຕາມຂະບວນຂອງການຜະລິດ
            </span>
          </div>
          <div class="row">
            <div class="col-sm mt-3">
              <span className="red">ວັດສະດຸຜະລິດມາຈກຫຍັງ?</span>
              <br></br>
              <span>
                <span className="ps-5">
                  ວັດສະດຸຂອງເຣົາແມ່ນ: ຜະລິດມາຈາກຝຸຸ່ນໄມ້, ໄຍໄມ້ປະສົມກັບ PVC (
                </span>
                <br></br>
                Polyvinyl chloride), ໃຊ້ວິທີການຂຶ້ນຣູບໂດຍການຫນີບເຢັນ ແລະ
                <br></br>
                ທື່ສຳຄັນທຸກຂັ້ນຕອນໃນການຜະລິດບໍ່ມີສ່ວນປະສົມຂອງກາວ,
                <br></br>
                ຈຶ່ງບໍ່ປ່ວຍສານເມຕານທີ່ເປັນອັນຕະລາຍຕໍ່ສິ່ງເເວດລ້ອມ ແລະ
                <br></br>
                ຜູ້ຢູ່ອາໄສ.
              </span>
              <div className="col mt-3">
                <span>
                  <span className="red">ສ່ວນລັກສະນະ </span>
                  ແມ່ນຈະອີງຕາມການຜະລິດທີ່ມີຄວາມແທດເຫມາະໃນການ
                  <br></br>
                  ໃຊ້ງານຕົວຈິງ ສຳລັບຮບຊົງໄມ້ທຽມປະເພດນີ້ຈະມີລັກສະນະເປັນຣູເພື່ອ
                  <br></br>
                  ຊ່ວຍໃນການປ້ອງກັນສຽງລົບກວນຈາກພາຍນອກ ແລະ ຂີ້ຝຸ່ນ
                </span>
              </div>
            </div>
            <div class="col-sm">
              <Row>
                <img className="ii" src="/1/2.png" />
                <img className="ii" src="/1/3.png" />
              </Row>
            </div>
          </div>

          <div className="row  mt-3">
            <Row>
              <img className="iii  mb-4" src="/1/4.png" />
            </Row>
          </div>
          {/* <div className="row">
            <div className="col-sm mt-2">
              <span className="">
                ພື້ນຜີວຂອງໄມ້ທຽນມີແບບສີຕາມລາຍໄມ້ຕ່າງໆ,ລາຍຫີນ, ລາຍຜ້າ...
                <br></br>
                ງານທົດແທນໄມ້ແທ້ໃນການຕົບແຕ່ງຊື່ງເປັນວັດສະດຸປະເພດຫນື່ງ
                <br></br>
                ທີ່ສາມາດຊ່ວຍຫລູດຜ່ອນໃນການຕັດໄມ້ທຳລາຍປ່າຊ່ວຍໃຫ້ສະພາບ
                <br></br>
                ແວດລ້ອມດີຂຶ້ນ
              </span>
            </div>
          </div> */}

          <Row1>
            <span className="">
              ພື້ນຜິວຂອງໄມ້ທຽນມີແບບສີຕາມລາຍໄມ້ຕ່າງໆ,ລາຍຫີນ, ລາຍຜ້າ...
              <br></br>
              ງານທົດແທນໄມ້ແທ້ໃນການຕົບແຕ່ງຊື່ງເປັນວັດສະດຸປະເພດຫນຶ່ງ
              <br></br>
              ທີ່ສາມາດຊ່ວຍຫລູດຜ່ອນໃນການຕັດໄມ້ທຳລາຍປ່າຊ່ວຍໃຫ້ສະພາບ
              <br></br>
              ແວດລ້ອມດີຂຶ້ນ
            </span>
            <div className="ms-5 w-50">
              <img className="w-25" src="/1/6.png" />
              <img className="w-25" src="/1/5.png" />
              <img className="w-25" src="/1/7.png" />
            </div>
          </Row1>

          <div className="row">
            <div className="col-sm mt-4">
              <span>
                ຈຸດເດັ່ນຂອງວັດສະດຸຄື: ກັນປວກ, ກັນນ້ຳ, ບໍ່ລາມໄຟ
                <br></br>
                ນ້ຳຫນັກເບົາ, ລະບາຍຄວາມຮ້ອນໄດ້ດີ, ສະດວກໃນການ
                <br></br>
                ຕິດຕັ້ງ, ຣັກສາຄວາມສະອາດງ່າຍ ແລະ ອາຍຸການໃຊ້
                <br></br>
                ງານດົນກວ່າ 25 ປີ
              </span>
            </div>
            <div className="col-sm">
              <img className="w-75" src="/1/9.png" />
            </div>
            <div className="col-sm"></div>
          </div>

          <div className="col w-100">
            <Row className="d-flex justify-content-start">
              <img className="mm" src="/1/11.png" />
              <img className="ia" src="/1/10.png" />
            </Row>
          </div>
          <div className="row"></div>
          <div className="row mt-5 boldt">
            <span>ການຕົບແຕ່ງດ້ວຍແຜ່ນໄມ້ທຽມ:</span>
          </div>
          <div className="row boldt">
            <Row>
              <div className="row mt-2 d-flex align-items-center text-center">
                <span className="mb-3">ຣູບເເບບ: ຕົບແຕ່ງເຕັມຝຳ</span>
                <Row>
                  <img className="ii" src="/1/ຝາ2.png" />
                  <img className="ii" src="/1/ຝາ3.png" />
                </Row>
              </div>
              <div className="row mt-2  d-flex align-items-center text-center">
                <span className="mb-3">ຣູບເເບບ: ການເຣັດກັນເປຶ້ອນ</span>
                <Row>
                  <img className="ii" src="/1/ກັນເປື້ອນ4.png" />
                  <img className="ii" src="/1/ກັນເປື້ອນ3.png" />
                </Row>
              </div>
            </Row>
            <Row>
              <Row>
                <img className="ii" src="/1/ຝາ5.png" />
                <img className="ii" src="/1/ຝາ1.png" />
              </Row>
              <Row>
                <img className="ii" src="/1/ກັນເປື້ອນ2.png" />
                <img className="ii" src="/1/ກັນເປື້ອນ1.png" />
              </Row>
            </Row>
          </div>

          <div className="row mt-3 boldt">
            <Row>
              <div className="row mt-5  d-flex align-items-center text-center">
                <span className="mb-3">ຮູບເເບບ: ຕົບແຕ່ງເພດານ</span>
                <Row>
                  <img className="ii" src="/1/ເພດານ1.png" />
                  <img className="ii" src="/1/ເພດານ2.png" />
                </Row>
              </div>
              <div className="row mt-5  d-flex align-items-center text-center">
                <span className="mb-3 ">ຮູບເເບບ: ກຳນໂອບເສົຳ</span>
                <Row>
                  <img className="ii" src="/1/ເສົາ2.png" />
                  <img className="ii" src="/1/ເສົາ1.png" />
                </Row>
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </Pa>
  );
};

export default One;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 2px;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 2px;
  align-content: center;
  align-items: center;
  justify-content: start;
  width: 100%;
  @media only screen and (max-width: 860px) {
    flex-direction: column;
  }
`;
const Pa = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  background-color: white;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  max-width: 1500px;
  //overflow: hidden;
  align-items: start;
  align-content: start;
  justify-content: start;
  width: 100%;
  margin-bottom: 50px;
  @media only screen and (max-width: 860px) {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
