import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin";
import React from "react";
import Masterpieces from "./components/Masterpieces";
import Products from "./components/Products";
import styled, { keyframes } from "styled-components";
import ReactWhatsapp from "react-whatsapp";
import ContactUs from "./components/ContactUs";
import ProductsSuggest from "./components/ProductsSuggest";
import Buying from "./components/Buying";
import { useState } from "react";
import ProductsDetailsSearch from "./pages/ProductsDetailsSearch";
import Cataloges from "./pages/Cataloges";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Search from "./components/Search";
import AboutUs from "./components/AboutUs";
import Install from "./components/Install";
import ProductsFooter from "./components/ProductsFooter";
import Com from "./components/com";
import PostDetail from "./components/PostDetail";

const Whatsapp = () => {
  return (
    <ReactWhatsapp number="8562057895999" message="">
      <FloatingButton src="https://cdn-icons-png.flaticon.com/512/124/124034.png"></FloatingButton>
    </ReactWhatsapp>
  );
};

function App() {
  const onLanguageSelected = (language) => {
    setLanguage(language);
  };

  const [language, setLanguage] = useState("LA");

  return (
    <BrowserRouter>
      <Whatsapp />
      <div>
        <MessengerCustomerChat
          pageId="273371500234428"
          appId="888192465479300"
        />
        <Routes>
          <Route path="admin" element={<Admin />} />
          <Route path="img" element={<Com />} />
          <Route path="postdt" element={<PostDetail />} />
          <Route
            path="pdt"
            element={<ProductsDetailsSearch language={language} />}
          />
          <Route path="/" element={<Home language={language} />} />
          <Route path="/suggest" element={<ProductsSuggest />} />
          <Route
            path="/masterpiece"
            element={<Masterpieces language={language} />}
          />
          <Route
            path="/contactus"
            element={<ContactUs Language={language} />}
          />
          <Route path="/buying" element={<Buying Language={language} />} />
          <Route path="/suggest" element={<ProductsSuggest />} />
          <Route
            path="/products"
            element={<Products all="ok" language={language} />}
          />
          <Route
            path="/productsf"
            element={<ProductsFooter language={language} />}
          />

          <Route path="/search" element={<Search />} />
          <Route path="/aboutus" element={<AboutUs Language={language} />} />
          <Route
            path="/installation"
            element={<Install language={language} />}
          />
          <Route
            path="/cataloges"
            element={<Cataloges Language={language} />}
          />
        </Routes>
        <NB>
          <Navbar LanguageSelected={onLanguageSelected} />
        </NB>
        <Footer Language={language} />
      </div>
    </BrowserRouter>
  );
}

const breatheAnimation = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const FloatingButton = styled.img`
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 100px;
  right: 23px;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation-name: ${breatheAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  @media only screen and (max-width: 860px) {
    // right: 10px;
  }
`;

const NB = styled.div`
  display: flex;
  margin-bottom: 45px;
`;

export default App;
