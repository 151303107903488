import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import BasedUrl from "../constrants";
import { useEffect, useState } from "react";

const TopSlide = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/banners/getAll").then((res) => {
        setBanners(res.data);
      });
    } catch (error) {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ");
    }
  }, []);

  return (
    <Container>
      <Carousel>
        {banners.map((banner) => (
          <Carousel.Item>
            <div key={banner.uuid}>
              <Image src={banner.link} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

const Image = styled.img`
  width: 1610px;
  height: auto;
  max-height: 500px;
  @media only screen and (max-width: 1600px) {
    width: 1200px;
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
  @media only screen and (max-width: 690px) {
    width: 100%;
    height: 230px;
  }
`;

const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 0;
  @media only screen and (max-width: 1400px) {
    width: 1200px;
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`;

export default TopSlide;
