import YouTube, { YouTubeProps } from "react-youtube";
import styled from "styled-components";
import { useEffect, useState } from "react";
import BasedUrl from "../constrants";
import axios from "axios";

const Videos = () => {
  const opts = {
    height: "120",
    width: "170",
    playerVars: {
      autoplay: 0,
    },
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/videos/getAll").then((res) => {
        setVideos(res.data);
      });
    } catch (error) {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ");
    }
  }, []);

  return (
    <Container>
      <Scroll>
        {videos.map((video) => (
          <YouTube key={video.uuid} videoId={video.link} opts={opts} />
        ))}
      </Scroll>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-content: flex-start;
  align-items: start;
  justify-content: start;
  @media only screen and (max-width: 860px) {
    max-width: 1000px;
    width: 100%;
    height: auto;
  }
`;

const Scroll = styled.div`
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 257px;
  width: 100%;
  align-items: start;
  margin-left: 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media only screen and (max-width: 860px) {
    flex-direction: row;
    overflow-y: hidden;
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
    gap: 15px;
    overflow-x: scroll;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 0px;
    height: 200px;
  }
`;

export default Videos;
