import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../constrants";
import { useEffect, useState } from "react";
import { Zoom } from "react-reveal";

const deleteCatlalog = async (uuid) => {
  axios
    .delete(BasedUrl + "/cataloges/deleteOne", { data: { uuid } })
    .then((res) => {
      alert("ລົບສຳເລັດ");
    })
    .catch((err) => {
      alert("ເກີດຂໍ້ຜີດພາດ ກະລູນາລອງໃໝ່");
    });
};

const Cataloges = (props) => {
  const [cataloges, setCataloges] = useState([]);
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    axios.get(BasedUrl + "/cataloges/getAll").then((res) => {
      setCataloges(res.data);
      setTrigger(false);
    });
  }, [trigger]);

  return (
    <Zoom>
      <Container>
        <Scroll>
          {cataloges.map((catalog) => (
            <Row>
              <Image src={catalog.img} />
              {props.admin == null && (
                <div>
                  {props.Language === "EN" && (
                    <button
                      onClick={() => {
                        window.location.href = catalog.link;
                      }}
                      type="button"
                      class="mt-3 btn btn-outline-primary w-100 "
                    >
                      Download
                    </button>
                  )}
                  {props.Language === "LA" && (
                    <button
                      onClick={() => {
                        window.location.href = catalog.link;
                      }}
                      type="button"
                      class="mt-3 btn btn-outline-primary w-100"
                    >
                      ດາວນໂຫລດ
                    </button>
                  )}
                </div>
              )}
              {props.admin === "ok" && (
                <button
                  onClick={() => {
                    if (
                      window.confirm("ທ່ານຕ້ອງການລົບແທ້ບໍ່?" + catalog.uuid) ===
                      true
                    ) {
                      deleteCatlalog(catalog.uuid);
                      setTrigger(true);
                    }
                  }}
                  type="button"
                  class="mt-3 btn btn-danger w-75"
                >
                  ລົບ
                </button>
              )}
            </Row>
          ))}
        </Scroll>
      </Container>
    </Zoom>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  margin-top: 120px;
  padding-top: 20px;
  min-height: 600px;
`;
const Row = styled.div`
  overflow-wrap: anywhere;
  width: 100%;
  height: 320px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-bottom: 20px;
  justify-content: center;
  &: hover {
    background-color: #eee;
  }
`;

const Scroll = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(10px, auto);
  overflow-x: scroll;
  overflow=: hidden;
  width: 100%;
  scroll-behavior: smooth;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Image = styled.img`
  width: 190px;
  height: 250px;
  padding: 5px;
  margin-bottom: 5px;
`;

export default Cataloges;
