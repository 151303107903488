import styled from "styled-components";

const ContactUs = (props) => {
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const Map = () => (
    <div>
      <Desktop className="ms-5 mt-4 mb-4">
        <iframe
          className="me-5"
          width="520"
          height="400"
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBT8FK-RGhSAmcql7cCNiVkulWdkaYW3pY
    &q=Space XBL Development Sole Co.,LTD  `}
        ></iframe>
      </Desktop>
      <Mobile>
        <iframe
          className="me-5"
          width="400"
          height="300"
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBT8FK-RGhSAmcql7cCNiVkulWdkaYW3pY
    &q=Space XBL Development Sole Co.,LTD  `}
        ></iframe>
      </Mobile>
    </div>
  );

  return (
    <Container>
      <Row>
        <Col className="align-items-center">
          <Pic src="/aa.jpg" />
          {props.Language === "LA" && (
            <img className="mt-4 w-75" src="/map.jpg" />
          )}
          {props.Language === "EN" && (
            <img className="mt-4 w-75" src="/mape.png" />
          )}
        </Col>
        <Col>
          {props.Language === "EN" && (
            <>
              <label>Purchasing advice providing</label>
              <label>Mr: Sam Tel: 020 5789 5999 </label>
              <label>Ms Thip Tel: 020 5688 4489</label>
              <label>Mr. Za Tel: 020 5428 5014</label>
              <label>Office number phone : 030 557 9777</label>
              <div>
                <Email src="https://icons.iconarchive.com/icons/dtafalonso/android-l/512/Gmail-icon.png" />
                <span>xbl.dve@gmail.com</span>
              </div>
              <div className="">
                <a href="https://api.whatsapp.com/message/DEZMKR2PMU3BO1?autoload=1&app_absent=0">
                  <Image src="https://cdn-icons-png.flaticon.com/512/124/124034.png" />
                </a>
                <a href="https://www.facebook.com/mouldingline">
                  <Image src="https://louisville.edu/mcconnellcenter/images/facebook_logos_PNG19748.png/image" />
                </a>
                <a href="https://www.youtube.com/channel/UC_ebF5a8zmBgWXoXt9L7Plg">
                  <Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" />
                </a>
                <a href="https://www.tiktok.com/@xbl.dev?_t=8VnsqaNFCt8&_r=1&fbclid=IwAR0_ymvtV1ZQTaUre6qn0kzOVKs3cJWlx33hHSus6rQNcbL4IMxMIYTC9Aw">
                  <Image src="https://www.freepnglogos.com/uploads/tik-tok-logo-png/tik-tok-android-application-logos-3.png" />
                </a>
              </div>
              <label className="mt-3">
                Address: Dongdok-Sikeut road, Nasiew village( in front Hom 12 ),
                Nasaithong, Vientiane Capital.
              </label>
              <label className="mt-3">Company map</label>
              <Map />
            </>
          )}
          {props.Language === "LA" && (
            <>
              <label>ໃຫ້ຄຳປຶກສາດ້ານການຊື້</label>
              <label>ທ່ານ ແຊມ ໂທ: 020 5789 5999 </label>
              <label>ທ່ານ ທິບພະສອນ ໂທ: 020 5688 4489</label>
              <label>ທ່ານ ອາພິຊາດ ໂທ: 020 5428 5014</label>
              <label>ຕິດຕໍ່ເບີຫ້ອງການ : 030 557 9777</label>
              <div>
                <Email src="https://icons.iconarchive.com/icons/dtafalonso/android-l/512/Gmail-icon.png" />
                <span>xbl.dve@gmail.com</span>
              </div>
              <div className="">
                <a href="https://api.whatsapp.com/message/DEZMKR2PMU3BO1?autoload=1&app_absent=0">
                  <Image src="https://cdn-icons-png.flaticon.com/512/124/124034.png" />
                </a>
                <a href="https://www.facebook.com/mouldingline">
                  <Image src="https://louisville.edu/mcconnellcenter/images/facebook_logos_PNG19748.png/image" />
                </a>
                <a href="https://www.youtube.com/channel/UC_ebF5a8zmBgWXoXt9L7Plg">
                  <Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" />
                </a>
                <a href="https://www.tiktok.com/@xbl.dev?_t=8VnsqaNFCt8&_r=1&fbclid=IwAR0_ymvtV1ZQTaUre6qn0kzOVKs3cJWlx33hHSus6rQNcbL4IMxMIYTC9Aw">
                  <Image src="https://www.freepnglogos.com/uploads/tik-tok-logo-png/tik-tok-android-application-logos-3.png" />
                </a>
              </div>
              <label className="mt-5">
                ບໍລິສັດຕັ້ງຢູ່: ຖະໜົນດົງໂດກ-ສີເກີດ, ບ້ານນາສ້ຽວ(ຕໍ່ຫນ້າຣ່ອມ 12),
                ເມືອງນາຊາຍທອງ, ນະຄອນຫຼວງວຽງຈັນ.
              </label>
              <label className="mt-3">ແຜນທີ່ບໍລິສັດ</label>

              <Map />
            </>
          )}
          <Mobile>
            {props.Language === "EN" && (
              <Pic src="/care.png" className="img-fluid w-100" />
            )}
            {props.Language === "LA" && (
              <Pic src="/carl.png" className="img-fluid w-100" />
            )}
          </Mobile>
          <Desktop className="ms-5 ps-5">
            {props.Language === "EN" && (
              <Pic src="/care.png" className=" img-fluid h-100" />
            )}
            {props.Language === "LA" && (
              <Pic src="/carl.png" className=" img-fluid h-100 me-5" />
            )}
          </Desktop>
        </Col>
      </Row>
    </Container>
  );

  
};

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 700px) {
    display: flex;
  }
`;

const Desktop = styled.div`
  display: flex;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;
const Image = styled.img`
  margin: 10px;
  width: 45px;
  height: 42px;
  border-radius: 10px;
`;
const Email = styled.img`
  margin: 10px;
  width: 20px;
  height: 25px;
  border-radius: 10px;
`;
const Container = styled.div`
  margin-top: 100px;
  padding-bottom: 50px;
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 999px) {
    flex-direction: column;
  }
`;

const Pic = styled.img`
  width: 80%;
  max-width: 700px;
  height: 400px;
  @media only screen and (max-width: 999px) {
    height: 350px;
  }
`;

export default ContactUs;
