import styled from "styled-components";
import { Zoom } from "react-reveal";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import BasedUrl from "../constrants";

const Install = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(BasedUrl + "/install/getAll")
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Zoom>
      <Container>
        <Scroll>
          {data.map((d) => (
            <Row
              onClick={() => {
                if (props.language === "LA") {
                  window.location.href = d.img;
                } else {
                  window.location.href = d.imgE;
                }
              }}
            >
              {props.language === "LA" && <Image src={d.img} />}
              {props.language === "EN" && <Image src={d.imgE} />}
              {props.language === "EN" && <h5>Installing {d.titleE}</h5>}
              {props.language === "LA" && <h5>ການຕິດຕັ້ງ {d.title}</h5>}
            </Row>
          ))}
        </Scroll>
      </Container>
    </Zoom>
  );
};

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-top: 90px;
  padding-top: 20px;
  min-height: 600px;
  padding-top: 50px;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
const Row = styled.div`
  overflow-wrap: anywhere;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  &: hover {
    background-color: #eee;
  }
`;

const Scroll = styled.div`
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(10px, auto);
  overflow-x: scroll;
  overflow=: hidden;
  width: 1610px;
  scroll-behavior: smooth;
  gap: 10px;
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Image = styled.img`
  width: 330px;
  height: 470px;
  padding: 5px;
  margin-bottom: 5px;
  @media only screen and (max-width: 999px) {
    width: 300px;
    height: 380px;
  }
`;

export default Install;
