import styled from "styled-components";
import Zoom from "react-reveal/Zoom";
import { useNavigate } from "react-router-dom";

const Footer = (props) => {
  const navigae = useNavigate();

  const onProductClick = (item) => {
    navigae("/products", {
      state: { item: item },
    });
    window.location.reload(false);
  };
  const onProductClickF = (item) => {
    navigae("/productsf", {
      state: { item: item },
    });
    window.location.reload(false);
  };
  const ProductsMenu1 = () => {
    if (props.Language === "LA") {
      return (
        <div className="gapp  pt-2 text-center">
          <button
            onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 40V-XBL")}
            className="link"
          >
            ແຜ່ນ 40v
          </button>
          <button
            onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 60V-XBL")}
            className="link"
          >
            ແຜ່ນ 60v
          </button>
          <button
            onClick={() => onProductClick("ບົວພຶ້ນ-XBL")}
            className="link"
          >
            ບົວພື້ນ
          </button>
          <button
            onClick={() => onProductClick("ບົວເພດານ -XBL")}
            className="link"
          >
            ບົວເພດານ
          </button>
          <button
            onClick={() => onProductClick("ບົວວົງກົບປະຕູ 80-XBL")}
            className="link"
          >
            ບົວວົງກົບປະຕູ
          </button>
        </div>
      );
    } else {
      return (
        <div className="col pt-2 text-center">
          <button
            onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 40V-XBL")}
            className="link"
          >
            wall panel 40V
          </button>
          <button
            onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 60V-XBL")}
            className="link"
          >
            wall panel 60V
          </button>

          <button
            onClick={() => onProductClick("ບົວພຶ້ນ-XBL")}
            className="link"
          >
            Skirting
          </button>
          <button
            onClick={() => onProductClick("ບົວເພດານ -XBL")}
            className="link"
          >
            Cornice
          </button>
          <button
            onClick={() => onProductClick("ບົວວົງກົບປະຕູ 80-XBL")}
            className="link"
          >
            Decoration 80
          </button>
        </div>
      );
    }
  };

  const ProductsMenu2 = () => {
    if (props.Language === "LA") {
      return (
        <div className="col pt-2 text-center">
          <button
            onClick={() => onProductClick("ບົວປົກ 75-XBL")}
            className="link"
          >
            ບົວປົກ 75
          </button>
          <button
            onClick={() => onProductClick("ບົວປ່ອງຢ້ຽມ 60- XBL")}
            className="link"
          >
            ບົວວົງກົບປ່ອງຢ້ຽມ
          </button>
          <button onClick={() => onProductClick("ບົວປາໂນ່")} className="link">
            ບົວປາໂນ່
          </button>
          <button
            onClick={() => onProductClick("ບົວປິດລ່ຽມ L 25 -XBL")}
            className="link"
          >
            ບົວປິດລ່ຽມ
          </button>
        </div>
      );
    } else {
      return (
        <div className="col pt-2">
          <button
            onClick={() => onProductClick("ບົວປົກ 75-XBL")}
            className="link"
          >
            Wall decoration 75
          </button>
          <button
            onClick={() => onProductClick("ບົວປ່ອງຢ້ຽມ 60- XBL")}
            className="link"
          >
            Waist line 60
          </button>
          <button onClick={() => onProductClick("ບົວປາໂນ່")} className="link">
            Decorative 36
          </button>
          <div
            onClick={() => onProductClick("ບົວປິດລ່ຽມ L 25 -XBL")}
            className="link"
          >
            External Corner
            <br></br>
            Frame L25
          </div>
        </div>
      );
    }
  };

  const ProductsMenu3 = () => {
    if (props.Language === "LA") {
      return (
        <div className="col pt-2 text-center">
          <button
            onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
            className="link"
          >
            ໄມ້ລະແນງຕັ້ງ
          </button>
          <button
            onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
            className="link"
          >
            ໄມ້ລະແນງເພດານ
          </button>
          <button
            onClick={() => onProductClick("ໄມ້ລະເເນງ 18-XBL")}
            className="link"
          >
            ໄມ້ລະແນງ 18
          </button>
          <button
            onClick={() => onProductClick("ໄມ້ລະເເນງ 20-XBL")}
            className="link"
          >
            ໄມ້ລະແນງ 20
          </button>
        </div>
      );
    } else {
      return (
        <div className="col pt-2">
          <button
            onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
            className="link"
          >
            Panttition
          </button>
          <button
            onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
            className="link"
          >
            false ceiling
          </button>
          <button
            onClick={() => onProductClick("ໄມ້ລະເເນງ 18-XBL")}
            className="link"
          >
            Salat wall panel 18
          </button>
          <button
            onClick={() => onProductClick("ໄມ້ລະເເນງ 20-XBL")}
            className="link"
          >
            Salat wall panel 20
          </button>
        </div>
      );
    }
  };

  const ProductsMenu4 = () => {
    if (props.Language === "LA") {
      return (
        <div className="col pt-2 text-center">
          <button
            onClick={() => onProductClick("ໄມ້ປູພື້ນ XBL")}
            className="link"
          >
            ໄມ້ປູພື້ນ SPC
          </button>
          <button
            onClick={() => onProductClickF("getAccessAll")}
            className="link"
          >
            ອຸປະກອນເສີມ
          </button>
          <button onClick={() => onProductClickF("getAll")} className="link">
            ວັດສະະດຸດ້ານໃນ
          </button>
          <button
            // onClick={() => onProductClick("ໄ")}
            className="link"
          >
            ວັດສະດຸດ້ານນອກ
          </button>
        </div>
      );
    } else {
      return (
        <div className="col pt-2 text-center">
          <button
            onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
            className="link"
          >
            SPC Flooring
          </button>
          <button
            onClick={() => onProductClickF("getAccessAll")}
            className="link"
          >
            Accessor
          </button>
          <button onClick={() => onProductClickF("getAll")} className="link">
            Export Interior
          </button>
          <button
            // onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
            className="link"
          >
            Extorior decoration
          </button>
        </div>
      );
    }
  };

  const Bottom = () => (
    <Container2>
      <div className="text-center pt-2">
        {props.Language === "LA" && (
          <div className="">
            <span>ບໍລິສັດ ເອັສບີແອວ ຈຳກັດຜູ້ດຽວ</span>
            <div className="co">
              <span className="mt-1">
                ຕັ້ງຢູ່: ຖະໜົນ ດົງໂດກ-ສີເກີດ ບ້ານ ນາສ້ຽວ (ຕໍ່ຫນ້າຣ່ອມ 12)
              </span>
              <span className="mt-1">ເມືອງ ນາຊາຍທອງ ແຂວງ ນະຄອນຫລວງວຽງຈັນ</span>
            </div>
          </div>
        )}
        {props.Language === "EN" && (
          <div className="mt-1">
            <span>XBL Sole Co,.Ltd.</span>
            <div className="co">
              <span className="mt-1">
                Address: Dongdok-Sikeut road, Nasiew village
              </span>
              <span className="mt-1">
                (At front Hom 12 ), Nasaithong, Vientiane Capital
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="footer-txt text-center">
        {props.Language === "LA" && (
          <div className="co mt-2">
            <span>ໂທ: (+856) 030 5579 777/ 020 7676 6969</span>
            <span className="mt-1">E-mail:xbl.dve@gmail.com </span>
            <span className="mt-1"> ເວັບໄຊທ໌: www.xbl.la </span>
          </div>
        )}
        {props.Language === "EN" && (
          <div className="co mt-2">
            <span className="mt-1">
              Tel: (+856) 030 5579 777/ 020 7676 6969
            </span>
            <span className="mt-1">E-mail:xbl.dve@gmail.com </span>
            <span className="mt-1">website: www.xbl.la </span>
          </div>
        )}
        <Line />
      </div>
      <div className=" text-center pt-2">
        {props.Language === "LA" && <span>ຕິດຕໍ່ພວກເຮົາ</span>}
        {props.Language === "EN" && <label className="mt-1">Contact Us</label>}
        <div className="col mt-1">
          <a href="https://api.whatsapp.com/message/DEZMKR2PMU3BO1?autoload=1&app_absent=0">
            <Image src="https://cdn-icons-png.flaticon.com/512/124/124034.png" />
          </a>
          <a href="https://www.facebook.com/mouldingline">
            <Image src="https://louisville.edu/mcconnellcenter/images/facebook_logos_PNG19748.png/image" />
          </a>
          <a href="https://www.youtube.com/channel/UC_ebF5a8zmBgWXoXt9L7Plg">
            <Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" />
          </a>
          <a href="https://www.tiktok.com/@xbl.dev?_t=8VnsqaNFCt8&_r=1&fbclid=IwAR0_ymvtV1ZQTaUre6qn0kzOVKs3cJWlx33hHSus6rQNcbL4IMxMIYTC9Aw">
            <Image src="https://www.freepnglogos.com/uploads/tik-tok-logo-png/tik-tok-android-application-logos-3.png" />
          </a>
        </div>
      </div>
    </Container2>
  );

  return (
    <Pa>
      <div className="pay2 pt-2">
        <div className="col text-white text-center">
          {props.Language === "LA" && <span>ຊ່ອງທາງການຊຳລະເງິນ</span>}
          {props.Language === "EN" && <span>Payment</span>}
          <div className="">
            <Image src="https://play-lh.googleusercontent.com/0fefMqcm2oUnw4Jo5gixiDrYwXIYUwsjXfaTQy-PNMt0ftkeCND_icGUR6OVmWl8-5Q" />
            <Image src="https://mahasab.com/wp-content/uploads/2019/07/2-2.png" />
            <Image src="https://xbl.la/1.png" />
          </div>
          <div className="">
            <Image src="https://2.bp.blogspot.com/-zadD5QYLp1E/Wq4GriCOmhI/AAAAAAAA7m8/CHeS_Z-huwM8nyCQq7CQGcmrxxxVNR2ggCLcBGAs/w1200-h630-p-k-no-nu/Lao%2BViet%2BBank.jpg" />
            <Image src="https://media-exp1.licdn.com/dms/image/C560BAQEUuRPfcYPuNg/company-logo_200_200/0/1597817272229?e=2147483647&v=beta&t=un2KkM6SoKNtbgtt-fIUabQwR68NMaE1ob2yWzT4Hk0" />
            <Image src="https://lh3.googleusercontent.com/-tirOKgV7U50/WBcLEn_uVqI/AAAAAAAAAus/vGEhrBK8p_I/s640/FB_IMG_1477905106281.jpg" />
          </div>
        </div>
      </div>
      <Container>
        <div className="pay pt-2">
          <div className="col text-white text-center">
            {props.Language === "LA" && <span>ຊ່ອງທາງການຊຳລະເງິນ</span>}
            {props.Language === "EN" && <span>Payment</span>}
            <div className="">
              <Image src="https://play-lh.googleusercontent.com/0fefMqcm2oUnw4Jo5gixiDrYwXIYUwsjXfaTQy-PNMt0ftkeCND_icGUR6OVmWl8-5Q" />
              <Image src="https://mahasab.com/wp-content/uploads/2019/07/2-2.png" />
              <Image src="https://xbl.la/1.png" />
            </div>
            <div className="">
              <Image src="https://2.bp.blogspot.com/-zadD5QYLp1E/Wq4GriCOmhI/AAAAAAAA7m8/CHeS_Z-huwM8nyCQq7CQGcmrxxxVNR2ggCLcBGAs/w1200-h630-p-k-no-nu/Lao%2BViet%2BBank.jpg" />
              <Image src="https://media-exp1.licdn.com/dms/image/C560BAQEUuRPfcYPuNg/company-logo_200_200/0/1597817272229?e=2147483647&v=beta&t=un2KkM6SoKNtbgtt-fIUabQwR68NMaE1ob2yWzT4Hk0" />
              <Image src="https://lh3.googleusercontent.com/-tirOKgV7U50/WBcLEn_uVqI/AAAAAAAAAus/vGEhrBK8p_I/s640/FB_IMG_1477905106281.jpg" />
            </div>
          </div>
        </div>
        <div className="col pt-2 footer-txt">
          {props.Language === "EN" && (
            <>
              <button onClick={() => navigae("/aboutus")} className="link">
                About Us
              </button>
              <button onClick={() => navigae("/contactus")} className="link">
                Contact Us
              </button>
              <button onClick={() => navigae("/buying")} className="link">
                Buying
              </button>
              <button
                onclick="location.href='https://xbl.la/application%20form.pdf'"
                type="button"
                className="link"
              >
                Job
              </button>
            </>
          )}
          {props.Language === "LA" && (
            <div className=" text-white mb-4">
              <button onClick={() => navigae("/aboutus")} className="link">
                ກ່ຽວກັບພວກເຣົາ
              </button>
              <button onClick={() => navigae("/contactus")} className="link">
                ຕິດຕໍ່ພວກເຮົາ
              </button>
              <button onClick={() => navigae("/buying")} className="link">
                ການຈັດຊື້
              </button>
              <button
                onclick="location.href='https://xbl.la/application%20form.pdf'"
                type="button"
                className="link"
              >
                ສະຫມັກວຽກ
              </button>
            </div>
          )}
        </div>
        <div className="col pt-2 footer-txt">
          {props.Language === "EN" && (
            <>
              <button onClick={() => navigae("/installation")} className="link">
                Installation
              </button>
              <button onClick={() => navigae("/cataloges")} className="link">
                Cataloges
              </button>
              <button onClick={() => navigae("/masterpiece")} className="link">
                Project
              </button>
            </>
          )}
          {props.Language === "LA" && (
            <div className=" text-white mb-4">
              <button onClick={() => navigae("/installation")} className="link">
                ການຕິດຕັ້ງ
              </button>
              <button onClick={() => navigae("/cataloges")} className="link">
                ແຄັດຕະລ໋ອກ
              </button>
              <button onClick={() => navigae("/masterpiece")} className="link">
                ຜົນງານ
              </button>
            </div>
          )}
        </div>
        <ProductsMenu1 />
        <ProductsMenu2 />
        <ProductsMenu3 />
        <ProductsMenu4 />
      </Container>
      <Bottom />
    </Pa>
  );
};

const Line = styled.div`
  height: 2px;
  width: 100%;
  margin-top: 15px;
  background-color: white;
`;
const Pa = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2983e3;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Container2 = styled.div`
  padding-left: 20px;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 50px;
  display: grid;
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  color: white;
  max-width: 1500px;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: 969px) {
    width: 100%;
    padding-top: 0px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 769px) {
    width: 100%;
    padding-top: 0px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Container = styled.div`
  overflow: hidden;
  padding-top: 20px;
  display: grid;
  color: white;
  max-width: 1500px;
  grid-template-columns: 250px 130px 130px 190px 190px 200px 150px;
  @media only screen and (max-width: 999px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 709px) {
    grid-template-columns: 120px 120px;
    padding-left: 30px;
    gap: 40px;
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  margin: 10px;
  width: 45px;
  height: 42px;
  border-radius: 10px;
`;

export default Footer;
