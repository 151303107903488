//const BasedUrl = "http://localhost:8000";
const BasedUrl = "http://xbl.la/api";

export default BasedUrl;

export const installingList = [
  {
    name: "ບົວປາໂນ່",
    nameE: "XVL-Decorative 36",
  },
  {
    name: "ບົວປິດລ່ຽມL",
    nameE: "XBL-External Corner Frame L 25 L",
  },
  {
    name: "ບົວປົກ75",
    nameE: "Wall Decoration 75",
  },
  {
    name: "ບົວພື້ນ",
    nameE: "XBL-Skirting",
  },
  {
    name: "ບົວເພດານ",
    nameE: "XBL-Cornice",
  },
  {
    name: "ບົວວົງກົບປ່ອງຢ້ຽມ",
    nameE: "XBL-Wasrt line 60",
  },
  {
    name: "ບົວວົງກົບປະຕູ",
    nameE: "XBL-Decoration 80",
  },
  {
    name: "ແຜ່ນໂຄງໄມ້",
    nameE: "XBL-Decoration 80",
  },
];
