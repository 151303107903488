import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import BasedUrl from "../constrants";
import Zoom from "react-reveal/Zoom";
import ReactImageMagnify from "react-image-magnify";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ProductDetailSection = (props) => {
  return (
    <div class="container mt-5">
      {props.language === "LA" && (
        <div className="row text-start">
          <label>ຊື່ສິນຄ້າ: {props.name}</label>
          <label>ສີ: {props.color}</label>
          <label>ລະຫັດສີ: {props.color_id}</label>
          <label>ຂະໜາດ: {props.size}</label>
          <label>ຜະລີດ ມາຈາກ: {props.made_from}</label>
          <div>
            <label>ຄຸນນະສົມບັດ: </label>
            <tr />
            <Properties src={props.properties} />
          </div>
        </div>
      )}
      {props.language === "EN" && (
        <div className="row text-start">
          <label>Name: {props.name}</label>
          <label>Color: {props.color}</label>
          <label>Code: {props.color_id}</label>
          <label>Size: {props.size}</label>
          <label>Made from: {props.made_from}</label>
          <div>
            <label>Properties: :</label>
            <tr />
            <Properties src={props.properties} />
          </div>{" "}
        </div>
      )}
    </div>
  );
};

const ProductUsingSection = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className=" text-start">
      <div class="row">
        {props.language === "LA" && <h4>ການຕິດຕັ້ງ</h4>}
        {props.language === "EN" && <h4>Installation</h4>}
        <div className="mt-3 w-50">
          <div>
            {isDesktopOrLaptop && (
              <div>
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      width: 400,
                      height: 400,
                      src: props.uimg1,
                    },
                    largeImage: {
                      src: props.uimg1,
                      width: 1000,
                      height: 1000,
                    },
                  }}
                />
              </div>
            )}
            {isTabletOrMobile && (
              <div>
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      width: 250,
                      height: 250,
                      src: props.uimg1,
                    },
                    largeImage: {
                      src: props.uimg1,
                      width: 800,
                      height: 800,
                    },
                  }}
                />
              </div>
            )}
            {/* <Viewer
                onMaskClick={() => setVisible(false)}
                visible={visible}
                onClose={() => {
                  setVisible(false);
                }}
                images={[
                  {
                    src: selectedImg,
                    alt: "",
                  },
                ]}
              /> */}
          </div>
        </div>
      </div>
      <div className="text-start">
        {props.language === "LA" && (
          <div className="row">
            <label className="mt-3">ລາຍລະອຽດການນຳໃຊ້: {props.using}</label>
          </div>
        )}
        {props.language === "EN" && (
          <div className="row">
            <label className="mt-3">Using details: {props.using}</label>
          </div>
        )}
      </div>
    </div>
  );
};

const ProductsDetailsSearch = (props) => {
  const [productDetail, setProductDetail] = useState({});
  const [selectedImg, setSelectedImg] = useState();
  const location = useLocation();

  let uuid = "";

  uuid = location.state.uuid;

  const navigate = useNavigate();
  useEffect(() => {
    try {
      axios
        .post(BasedUrl + "/products/getOne", { uuid: uuid })
        .then((res) => {
          setProductDetail(res.data[0]);
          setSelectedImg(res.data[0].img1);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      return error;
    }
  }, [uuid]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div o class="container bg-white  mb-3 pb-5">
      <Zoom>
        <div className="col">
          <Icon
            onClick={() => {
              navigate("/");
            }}
            src="https://icon-library.com/images/back-arrow-icon/back-arrow-icon-9.jpg"
          />
        </div>
        <div class="row">
          <div class="col-sm mb-5">
            <div className=" w-50">
              {isDesktopOrLaptop && (
                <div>
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        width: 400,
                        height: 400,
                        src: selectedImg,
                      },
                      largeImage: {
                        src: selectedImg,
                        width: 1000,
                        height: 1000,
                      },
                    }}
                  />
                </div>
              )}
              {isTabletOrMobile && (
                <div>
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        width: 250,
                        height: 250,
                        src: selectedImg,
                      },
                      largeImage: {
                        src: selectedImg,
                        width: 800,
                        height: 800,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Image
              onClick={() => setSelectedImg(productDetail.img1)}
              alt=""
              src={productDetail.img1}
            />
            <Image
              onClick={() => setSelectedImg(productDetail.img2)}
              alt=""
              src={productDetail.img2}
            />
            <Image
              onClick={() => setSelectedImg(productDetail.img3)}
              alt=""
              src={productDetail.img3}
            />
            <Image
              onClick={() => setSelectedImg(productDetail.img4)}
              alt=""
              src={productDetail.img4}
            />
            <Image
              onClick={() => setSelectedImg(productDetail.img5)}
              alt=""
              src={productDetail.img5}
            />

            {props.language === "EN" && (
              <ProductDetailSection
                color={productDetail.colorE}
                language={props.language}
                size={productDetail.size}
                name={productDetail.p_nameE}
                id={productDetail.id}
                color_id={productDetail.color_id}
                made_from={productDetail.made_fromE}
                properties={productDetail.propertiesE}
              />
            )}
            {props.language === "LA" && (
              <ProductDetailSection
                color={productDetail.color}
                language={props.language}
                size={productDetail.size}
                name={productDetail.p_name}
                id={productDetail.id}
                color_id={productDetail.color_id}
                made_from={productDetail.made_from}
                properties={productDetail.properties}
              />
            )}
            {props.language === "EN" && (
              <ProductUsingSection
                uimg1={productDetail.uimg1}
                language={props.language}
                using={productDetail.usingE}
                setting={productDetail.settingE}
              />
            )}
            {props.language === "LA" && (
              <ProductUsingSection
                uimg1={productDetail.uimg1}
                language={props.language}
                using={productDetail.using}
                setting={productDetail.setting}
              />
            )}
          </div>
        </div>
      </Zoom>
    </div>
  );
};
export default ProductsDetailsSearch;

const Image = styled.img`
  width: 120px;
  height: 120px;
  padding: 10px;
  &: hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
  @media only screen and (max-width: 500px) {
    padding: 5px;
    width: 70px;
    height: 70px;
  }
`;

const Icon = styled.img`
  margin-top: 180px;
  margin-bottom: 40px;
  padding: 10px;
  width: 60px;
  height: 60px;
  &: hover {
    background-color: #eee;
  }
`;

const Properties = styled.img`
  width: 10px;
  height: 20px;
`;
