import { useState, useEffect } from "react";
import React from "react";
import styled from "styled-components";
import "../index.css";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import { useLocation } from "react-router-dom";
import axios from "axios";
import BasedUrl from "../constrants";

const Navbar = (props) => {
  const navigate = useNavigate();

  const [Language, setLanguage] = useState("LA");

  const location = useLocation();

  const [menuChange, setMenuChange] = useState(false);
  const [todayCount, setTodayCount] = useState(0);
  const [allCount, setAllCount] = useState(0);

  const [menus, setMenus] = useState({
    home: "Home",
    suggest: "Suggest",
    products: "Products",
    category: "Category",
    installation: "Installation",
    projects: "Projects",
    buying: "Buying",
    contactus: "Contact Us",
    aboutus: "About Us",
    job: "Job",
  });

  const [menu, setMenu] = useState({
    home: "menu",
    suggest: "menu",
    products: "menu",
    catalog: "menu",
    installation: "menu",
    projects: "menu",
    buying: "menu",
    aboutus: "menu",
    contactus: "menu",
    job: "menu",
  });

  useEffect(() => {
    if (Language === "EN") {
      setMenus({
        home: "Home",
        suggest: "Suggest",
        products: "Products",
        category: "Catalogue",
        installation: "Installation",
        projects: "Projects",
        aboutus: "About Us",
        buying: "Buying",
        contactus: "Contact Us",
        job: "Job",
      });
    } else if (Language === "LA") {
      setMenus({
        home: "ໜ້າຫຼັກ",
        suggest: "ແນະນຳ",
        products: "ສິນຄ້າທັງໝົດ",
        category: "ແຄັດຕາລ໋ອກ",
        installation: "ການຕິດຕັ້ງ",
        projects: "ຜົນງານ",
        aboutus: "ກ່ຽວກັບພວກເຮົາ",
        buying: "ການຈັດຊື້",
        contactus: "ຕິດຕໍ່ພວກເຮົາ",
        job: "ສະໝັກວຽກ",
      });
    }
  }, [Language]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setMenu({
          home: "menu active",
          suggest: "menu",
          products: "menu",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;
      case "/suggest":
        setMenu({
          home: "menu",
          suggest: "menu active",
          products: "menu",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;
      case "/products":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu active",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;
      case "/productsf":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu active",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;
      case "/cataloges":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu",
          catalog: "menu active",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;

      case "/installation":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu",
          catalog: "menu",
          installation: "menu active",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;

      case "/masterpiece":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu",
          catalog: "menu",
          installation: "menu",
          projects: "menu active",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;

      case "/buying":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu active",
          aboutus: "menu",
          contactus: "menu",
          job: "menu",
        });
        break;
      case "/aboutus":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu active",
          contactus: "menu",
          job: "menu",
        });
        break;
      case "/contactus":
        setMenu({
          home: "menu",
          suggest: "menu",
          products: "menu",
          catalog: "menu",
          installation: "menu",
          projects: "menu",
          buying: "menu",
          aboutus: "menu",
          contactus: "menu active",
          job: "menu",
        });
    }
    setMenuChange(false);
  }, [menuChange]);

  // function setMenuClass(key) {

  //   // Object.keys(menuInit).forEach(function (index) {
  //   //   if (key == index) {
  //   //     menuInit[index] = "menu active";
  //   //   } else menuInit[index] = "menu";
  //   // });
  // }

  // Get visitor count

  useEffect(() => {
    axios.get(BasedUrl + "/visitors/getToday").then((res) => {
      setTodayCount(res.data[0].total);
    });
    axios.get(BasedUrl + "/visitors/getAll").then((res) => {
      setAllCount(res.data[0].total);
    });
  }, []);

  const onProductClick = (item) => {
    setMenuChange(true);
    setSidebar(false);

    navigate("/products", {
      state: { item: item },
    });
  };

  const ProductsTab = () => {
    const Lanaeng = () => {
      if (Language === "LA")
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
                className="dropdown-item"
              >
                <label> ໄມ້ລະແນງຕັ້ງ</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
                className="dropdown-item"
              >
                <label> ໄມ້ລະແນງເພດານ</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະເເນງ 18-XBL")}
                className="dropdown-item"
              >
                <label> ໄມ້ລະແນງ 18</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະເເນງ 20-XBL")}
                className="dropdown-item"
              >
                <label> ໄມ້ລະແນງ 20</label>
              </a>
            </li>
          </ul>
        );
      else {
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
                className="dropdown-item"
              >
                <label> XBL-Panttition</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
                className="dropdown-item"
              >
                <label> XBL-false ceiling</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະເເນງ 18-XBL")}
                className="dropdown-item"
              >
                <label> XBL-Salat wall panel 18</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ລະເເນງ 20-XBL")}
                className="dropdown-item"
              >
                <label> XBL-Salat wall panel 20</label>
              </a>
            </li>
          </ul>
        );
      }
    };

    const Pouphuen = () => {
      if (Language === "LA")
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ປູພື້ນ XBL")}
                lass="dropdown-item"
              >
                <label> ໄມ້ປູພື້ນ</label>
              </a>
            </li>
          </ul>
        );
      else {
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ໄມ້ປູພື້ນ XBL")}
                lass="dropdown-item"
              >
                <label> XBL Floor</label>
              </a>
            </li>
          </ul>
        );
      }
    };
    const Boua = () => {
      if (Language === "LA")
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ບົວພຶ້ນ-XBL")}
                className="dropdown-item"
              >
                <label> ບົວພື້ນ</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວເພດານ -XBL")}
                className="dropdown-item"
              >
                <label> ບົວເພດານ</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວວົງກົບປະຕູ 80-XBL")}
                className="dropdown-item"
              >
                <label> ບົວວົງກົບປະຕູ</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປົກ 75-XBL")}
                className="dropdown-item"
              >
                <label> ບົວປົກ 75</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປ່ອງຢ້ຽມ 60- XBL")}
                className="dropdown-item"
              >
                <label> ບົວວົງກົບປ່ອງຢ້ຽມ</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປາໂນ່")}
                className="dropdown-item"
              >
                <label> ບົວປາໂນ່</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປິດລ່ຽມ L 25 -XBL")}
                className="dropdown-item"
              >
                <label> ບົວປິດລ່ຽມ L</label>
              </a>
            </li>
          </ul>
        );
      else {
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ບົວພຶ້ນ-XBL")}
                className="dropdown-item"
              >
                <label> XBL-Skirting</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວເພດານ -XBL")}
                className="dropdown-item"
              >
                <label> XBL - Cornice</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວວົງກົບປະຕູ 80-XBL")}
                className="dropdown-item"
              >
                <label> XBL-Decoration 80</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປົກ 75-XBL")}
                className="dropdown-item"
              >
                <label>Wall decoration 75</label>
              </a>
            </li>

            <li>
              <a
                onClick={() => onProductClick("ບົວປ່ອງຢ້ຽມ 60- XBL")}
                className="dropdown-item"
              >
                <label> XBL-Waist line 60</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປາໂນ່")}
                className="dropdown-item"
              >
                <label> XBL-Decorative 36</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ບົວປິດລ່ຽມ L 25 -XBL")}
                className="dropdown-item"
              >
                <label> XBL- External Corner Frame L 25 L</label>
              </a>
            </li>
          </ul>
        );
      }
    };
    const Maothien = () => {
      if (Language == "LA")
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 40V-XBL")}
                className="dropdown-item"
              >
                <label> ແຜ່ນ 40v</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 60V-XBL")}
                className="dropdown-item"
              >
                <label> ແຜ່ນ 60v</label>
              </a>
            </li>
          </ul>
        );
      else {
        return (
          <ul className="dropdown-menu">
            <li>
              <a
                onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 40V-XBL")}
                className="dropdown-item"
              >
                <label> XBL wall panel 40V</label>
              </a>
            </li>
            <li>
              <a
                onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 60V-XBL")}
                className="dropdown-item"
              >
                <label>XBL wall panel 60V</label>
              </a>
            </li>
          </ul>
        );
      }
    };

    const Inside = () => {
      if (Language === "LA")
        return (
          <li className="dropdown-submenu">
            <a className="dropdown-item dropdown-toggle">ພາຍໃນ</a>
            <ul className="dropdown-menu">
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  ປະເພດແຜ່ນໄມ້ທຽມ
                </a>
                <Maothien />
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  ປະເພດບົວ
                </a>
                <Boua />
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  ໄມ້ລະແນງ
                </a>
                <Lanaeng />
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  ໄມ້ປູພື້ນ
                </a>
                <Pouphuen />
              </li>
            </ul>
          </li>
        );
      else {
        return (
          <li className="dropdown-submenu">
            <a className="dropdown-item dropdown-toggle">Export interior</a>
            <ul className="dropdown-menu">
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  XBL wall panel
                </a>
                <Maothien />
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Moulding Lines
                </a>
                <Boua />
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  Salat Wall Panel
                </a>
                <Lanaeng />
              </li>
              <li className="dropdown-submenu">
                <a className="dropdown-item dropdown-toggle" href="#">
                  XBL Floor
                </a>
                <Pouphuen />
              </li>
            </ul>
          </li>
        );
      }
    };

    const Option = () => {
      return (
        <li className="dropdown-submenu">
          {Language === "LA" && (
            <li className="dropdown-submenu">
              <a className="dropdown-item dropdown-toggle">ອຸປະກອນເສີມ</a>
              <ul className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("XBL -ກາວຕາປູ")}
                >
                  XBL -ກາວຕາປູ
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ກິບເຫລັກ")}
                >
                  ກິບເຫຼັກ
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ຂອບສະແຕນເຫລດ")}
                >
                  ຂອບສະແຕນເຫລດ
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ລູກແມັກ")}
                >
                  ລູກແມັກ
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ເຫຼັກຊີລາຍນ໌")}
                >
                  ເຫຼັກຊີລາຍນ໌/
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ເຫຼັກສາກ")}
                >
                  ເຫຼັກສາກ
                </a>
              </ul>
            </li>
          )}
          {Language === "EN" && (
            <li className="dropdown-submenu">
              <a className="dropdown-item dropdown-toggle">Accessory</a>
              <ul className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("XBL -ກາວຕາປູ")}
                >
                  XBL-Liquid nail construction adhesive
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ກິບເຫຼັກ")}
                >
                  XBL-Integrated wall fastener
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ຂອບສະແຕນເຫລດ")}
                >
                  Stainless steel line
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ລູກແມັກ")}
                >
                  Max
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ເຫຼັກຊີລາຍນ໌")}
                >
                  C line
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => onProductClick("ເຫຼັກສາກ")}
                >
                  Corner Screw
                </a>
              </ul>
            </li>
          )}
        </li>
      );
    };

    const Outside = () => {
      if (Language === "LA")
        return (
          <li className="dropdown-submenu">
            <a className="dropdown-item dropdown-toggle">ພາຍນອກ</a>
          </li>
        );
      else {
        return (
          <li className="dropdown-submenu">
            <a className="dropdown-item dropdown-toggle">Exterior decoration</a>
          </li>
        );
      }
    };

    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link text-white dropdown-toggle"
          href="http://example.com"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {menus.products}
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Inside />
          <Outside />
          <Option />
        </ul>
      </li>
    );
  };

  const Top = () => {
    return (
      <div className="top">
        <div className="top-content">
          <div className="right p-0">
            <TextImg className="mt-4" src="/left2.png" />
            <Icon3 className="mt-3" src="/left3.png" />
          </div>
          <div className="logo">
            <Logo src="/logo.png" />
          </div>
          <div className="search">
            <Search height="40px" />
          </div>
          <div className="search ms-0 ps-0 ms-0"></div>
          <div className="right">
            <div className="ms-0 row">
              <Icon3 className="mt-5" src="/a.png" />
              <Icon1 className="mt-5" src="/free.png" />
            </div>
            <div className="col text-end mt-4">
              <Icon src="/profike.png" />
            </div>
            {Language === "LA" && (
              <div className="mt-4">
                <div className="row text-start">
                  <label className="txt">
                    ເຂົ້າຊົມມື້ນີ້ {" " + todayCount}
                  </label>
                </div>
                <div className="row ">
                  <label className="txt">ທັງຫມົດ {" " + allCount}</label>
                </div>
              </div>
            )}
            {Language === "EN" && (
              <div className="mt-4">
                <div className="row">
                  <label className="txt">
                    Today visitors {" " + todayCount}
                  </label>
                </div>
                <div className="row">
                  <label className="txt">Total visitors {" " + allCount}</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const [l1, setl1] = useState("hide");
  const [showWxtrenal, setShowexternal] = useState(false);
  const [showAccess, setShowAccess] = useState(false);

  const onShowProducts = () => {
    if (l1 === "show") setl1("hide");
    else if (l1 === "hide") setl1("show");
  };

  const Productsmb = () => {
    const XBL_wall = () => (
      <div>
        {Language === "EN" && (
          <div>
            <a
              onClick={() => {
                onProductClick("ແຜ່ນໄມ້ທຽມ 40V-XBL");
              }}
              className="l3 products-mb menu"
            >
              XBL-wall panel 40V
            </a>
            <a
              onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 60V-XBL")}
              className="l3 products-mb menu"
            >
              XBL-wall panel 60V
            </a>
          </div>
        )}
        {Language === "LA" && (
          <div>
            <a
              onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 40V-XBL")}
              className="l3 products-mb menu"
            >
              ແຜ່ນ 40V
            </a>
            <a
              onClick={() => onProductClick("ແຜ່ນໄມ້ທຽມ 60V-XBL")}
              className="l3 products-mb menu"
            >
              ແຜ່ນ 60V
            </a>
          </div>
        )}
      </div>
    );

    const Moulding_Line = () => (
      <div>
        {Language === "EN" && (
          <div>
            <a
              onClick={() => onProductClick("ບົວພຶ້ນ-XBL")}
              className="l3 products-mb menu"
            >
              XBL-Skirting
            </a>
            <a
              onClick={() => onProductClick("ບົວເພດານ -XBL")}
              className="l3 products-mb menu"
            >
              XBL-Cornice
            </a>
            <a
              onClick={() => onProductClick("ບົວວົງກົບປະຕູ 80-XBL")}
              className="l3 products-mb menu"
            >
              XBL-Decorative 80
            </a>
            <a
              onClick={() => onProductClick("ບົວປົກ 75-XBL")}
              className="l3 products-mb menu"
            >
              XBL-Decorative 75
            </a>
            <a
              onClick={() => onProductClick("ບົວປ່ອງຢ້ຽມ 60- XBL")}
              className="l3 products-mb menu"
            >
              XBL-Wast line 60
            </a>
            <a
              onClick={() => onProductClick("ບົວປາໂນ່")}
              className="l3 products-mb menu"
            >
              XBL-Decorative 36
            </a>
            <a
              onClick={() => onProductClick("ບົວປິດລ່ຽມ L 25 -XBL")}
              className="l3 products-mb menu"
            >
              XBL-External Corner Frame L 25 L
            </a>
          </div>
        )}
        {Language === "LA" && (
          <div>
            <a
              onClick={() => onProductClick("ບົວພຶ້ນ-XBL")}
              className="l3 products-mb menu"
            >
              ບົວພື້ນ
            </a>
            <a
              onClick={() => onProductClick("ບົວເພດານ -XBL")}
              className="l3 products-mb menu"
            >
              ບົວເພດານ
            </a>
            <a
              onClick={() => onProductClick("ບົວວົງກົບປະຕູ 80-XBL")}
              className="l3 products-mb menu"
            >
              ບົວວົງກົບປະຕູ
            </a>
            <a
              onClick={() => onProductClick("ບົວປົກ 75-XBL")}
              className="l3 products-mb menu"
            >
              ບົງ 75
            </a>
            <a
              onClick={() => onProductClick("ບົວປ່ອງຢ້ຽມ 60- XBL")}
              className="l3 products-mb menu"
            >
              ບົວວົງກົບປ່ອງຢ້ຽມ
            </a>
            <a
              onClick={() => onProductClick("ບົວປາໂນ່")}
              className="l3 products-mb menu"
            >
              ບົວປາໂນ່
            </a>
            <a
              onClick={() => onProductClick("ບົວປິດລ່ຽມ L 25 -XBL")}
              className="l3 products-mb menu"
            >
              ບົວປິດລ່ຽມ L
            </a>
          </div>
        )}
      </div>
    );

    const Salat_wall = () => (
      <div>
        {Language === "EN" && (
          <div>
            <a
              onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
              className="l3 products-mb menu"
            >
              XBL-Partition
            </a>
            <a
              onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
              className="l3 products-mb menu"
            >
              XBL-False ceiling
            </a>
            <a
              onClick={() => onProductClick("ໄມ້ລະເເນງ 18-XBL")}
              className="l3 products-mb menu"
            >
              XBL-Salat wall panel 18
            </a>
            <a
              onClick={() => onProductClick("ໄມ້ລະເເນງ 20-XBL")}
              className="l3 products-mb menu"
            >
              XBL-Salat wall panel 20
            </a>
          </div>
        )}
        {Language === "LA" && (
          <div>
            <a
              onClick={() => onProductClick("ໄມ້ລະແນງຕັ້ງ-XBL")}
              className="l3 products-mb menu"
            >
              ໄມ້ລະແນງຕັ້ງ
            </a>
            <a
              onClick={() => onProductClick("ໄມ້ລະແນງເພດານ-XBL")}
              className="l3 products-mb menu"
            >
              ໄມ້ລະແນງເພດານ
            </a>
            <a
              onClick={() => onProductClick("ໄມ້ລະເເນງ 18-XBL")}
              className="l3 products-mb menu"
            >
              ໄມ້ລະເເນງ 18
            </a>
            <a
              onClick={() => onProductClick("ໄມ້ລະເເນງ 20-XBL")}
              className="l3 products-mb menu"
            >
              ໄມ້ລະເເນງ 20
            </a>
          </div>
        )}
      </div>
    );

    const Floor = () => (
      <div>
        {Language === "EN" && (
          <div>
            <a
              onClick={() => onProductClick("ໄມ້ປູພື້ນ XBL")}
              className="l3 products-mb menu"
            >
              XBL-Floor
            </a>
          </div>
        )}
        {Language == "LA" && (
          <div>
            <a
              onClick={() => onProductClick("ໄມ້ປູພື້ນ XBL")}
              className="l3 products-mb menu"
            >
              ໄມ້ປູພື້ນ
            </a>
          </div>
        )}
      </div>
    );

    const External = () => (
      <div>
        <a className="l2 products-mb menu">
          {Language === "EN" && <> XBL-wall panel</>}
          {Language === "LA" && <> ປະເພດແຜ່ນໄມ້ທຽມ</>}
        </a>
        <XBL_wall />
        <a className="l2 products-mb menu">
          {Language === "EN" && <> Moulding Lines</>}
          {Language === "LA" && <> ປະເພດບົວ</>}
        </a>
        <Moulding_Line />
        <a className="l2 products-mb menu">
          {Language === "EN" && <>Salat Wall Pane</>}
          {Language === "LA" && <>ໄມ້ລະເເນງ</>}l
        </a>
        <Salat_wall />
        <a className="l2 products-mb menu">
          {Language === "EN" && <>XBL-Floor</>}
          {Language === "LA" && <>ໄມ້ປູພື້ນ</>}l
        </a>
        <Floor />
      </div>
    );

    const Accessory = () => (
      <div>
        {Language === "LA" && (
          <div>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("XBL -ກາວຕາປູ")}
            >
              XBL -ກາວຕາປູ
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ກິບເຫລັກ")}
            >
              ກິບເຫລັກ
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ຂອບສະແຕນເຫລດ")}
            >
              ຂອບສະແຕນເຫລດ
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ລູກແມັກ")}
            >
              ລູກແມັກ
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ເຫຼັກຊີລາຍນ໌")}
            >
              ເຫຼັກຊີລາຍນ໌
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ເຫຼັກສາກ")}
            >
              ເຫຼັກສາກ
            </a>
          </div>
        )}
        {Language === "EN" && (
          <div>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("XBL -ກາວຕາປູ")}
            >
              XBL-Liquid nail construction adhesive
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ກິບເຫຼັກ")}
            >
              XBL-Integrated wall fastener
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ຂອບສະແຕນເຫລດ")}
            >
              Stainless steel line
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ລູກແມັກ")}
            >
              Max
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ເຫຼັກຊີລາຍນ໌")}
            >
              C line
            </a>
            <a
              class="products-mb menu l2"
              onClick={() => onProductClick("ເຫຼັກສາກ")}
            >
              Corner Screw
            </a>
          </div>
        )}
      </div>
    );

    return (
      <div className="">
        <div>
          <a onClick={() => onShowProducts()} className="products-mb menu">
            {Language === "EN" && <> Products</>}
            {Language === "LA" && <> ສິນຄ້າທັງໝົດ</>}
          </a>
          <div className={l1}>
            <a
              onClick={() => setShowexternal(!showWxtrenal)}
              className="products-mb menu l1"
            >
              {Language === "EN" && <> Export interior</>}
              {Language === "LA" && <> ພາຍໃນ</>}
            </a>
            {showWxtrenal && <External />}
            <a className="products-mb menu l1">
              {Language === "EN" && <> Interior decoration</>}
              {Language === "LA" && <> ພາຍນອກ</>}
            </a>
            <a
              onClick={() => setShowAccess(!showAccess)}
              className="products-mb menu l1"
            >
              {Language === "EN" && <> Accessory</>}
              {Language === "LA" && <> ອຸປະກອນເສີມ</>}
            </a>
            {showAccess && <Accessory />}
          </div>
        </div>
      </div>
    );
  };

  const [showSidebar, setSidebar] = useState(false);
  const [overflow, setOverflow] = useState("");

  const Navbar = () => {
    const Menu = styled.img`
      padding-left: 10px;
      width: 45px;
      hieght: 45px;
      margin-top: 13px;
      margin-bottom: 20px;
    `;
    return (
      <div className={"sidenav " + overflow}>
        <div className="roww">
          <Menu
            className="mt-3"
            onClick={() => {
              if (overflow === "") setOverflow("overflow");
              else setOverflow("");
              setSidebar(!showSidebar);
            }}
            src="/menu.png"
          />
          {Language == "EN" && (
            <div className="dropdown mt-1 me-3 z">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Flag src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png" />{" "}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Row
                  onClick={() => {
                    setLanguage("EN");
                    props.LanguageSelected("EN");
                  }}
                >
                  <Flag
                    alt="English"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
                  />
                </Row>
                <Row
                  onClick={() => {
                    setLanguage("LA");
                    props.LanguageSelected("LA");
                  }}
                >
                  <Flag src="https://www.laos-guide-999.com/images/Flag-of-LaoPDR.png" />
                </Row>
              </div>
            </div>
          )}
          {Language == "LA" && (
            <div className="dropdown">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Flag
                  className="mt-2"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/1200px-Flag_of_Laos.svg.png"
                />{" "}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Row
                  onClick={() => {
                    setLanguage("EN");
                    props.LanguageSelected("EN");
                  }}
                >
                  <Flag
                    alt="English"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
                  />
                </Row>
                <Row
                  onClick={() => {
                    setLanguage("LA");
                    props.LanguageSelected("LA");
                  }}
                >
                  <Flag src="https://www.laos-guide-999.com/images/Flag-of-LaoPDR.png" />
                </Row>
              </div>
            </div>
          )}
        </div>

        {showSidebar && (
          <div>
            <a className="menu" href="/">
              {menus.home}
            </a>
            <Productsmb />
            <a className="menu" href="/cataloges">
              {menus.category}
            </a>
            <a className="menu" href="/installation">
              {menus.installation}
            </a>
            <a className="menu" href="/masterpiece">
              {menus.projects}
            </a>
            <a className="menu" href="/buying">
              {menus.buying}
            </a>
            <a className="menu" href="/aboutus">
              {menus.aboutus}
            </a>
            <a className="menu" href="/contactus">
              {menus.contactus}
            </a>
            <a className="menu" href="http://xbl.la/application form.pdf">
              {menus.job}
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <Container className="bg-white">
      <Top />
      <Navbar />
      <nav class="navbar navbar-expand-md navbar-light bg m-0 p-0">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-around"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ">
            <a className={menu.home} href="/">
              <HomeIcon src="https://www.nicepng.com/png/full/115-1153942_white-home-icon-png-white-home-logo-transparent.png" />
              {menus.home}
            </a>
            <a className={menu.products + " products"}>
              <ProductsTab />
            </a>
            <Productsmb />
            <a className={menu.catalog} href="/cataloges">
              {menus.category}
            </a>
            <a className={menu.installation} href="/installation">
              {menus.installation}
            </a>
            <a className={menu.projects} href="/masterpiece">
              {menus.projects}
            </a>
            <a className={menu.buying} href="/buying">
              {menus.buying}
            </a>
            <a className={menu.aboutus} href="/aboutus">
              {menus.aboutus}
            </a>
            <a className={menu.contactus} href="contactus">
              {menus.contactus}
            </a>
            <a className="menu" href="http://xbl.la/application form.pdf">
              {menus.job}
            </a>
            <SearchContainer className="hide">
              <Search height="35px" />
              {Language == "EN" && (
                <div className="dropdown">
                  <button
                    className="btn btn-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <Icon
                      className="p-2"
                      src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
                    />{" "}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Row
                      onClick={() => {
                        setLanguage("EN");
                        props.LanguageSelected("EN");
                      }}
                    >
                      <Icon
                        className="p-2"
                        alt="English"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
                      />
                    </Row>
                    <Row
                      onClick={() => {
                        setLanguage("LA");
                        props.LanguageSelected("LA");
                      }}
                    >
                      <Icon
                        className="p-2"
                        src="https://www.laos-guide-999.com/images/Flag-of-LaoPDR.png"
                      />
                    </Row>
                  </div>
                </div>
              )}
              {Language == "LA" && (
                <div className="dropdown">
                  <button
                    className="btn btn-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <Icon
                      className="p-2"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/1200px-Flag_of_Laos.svg.png"
                    />{" "}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Row
                      onClick={() => {
                        setLanguage("EN");
                        props.LanguageSelected("EN");
                      }}
                    >
                      <Icon
                        className="p-2"
                        alt="English"
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
                      />
                    </Row>
                    <Row
                      onClick={() => {
                        setLanguage("LA");
                        props.LanguageSelected("LA");
                      }}
                    >
                      <Icon
                        className="p-2"
                        src="https://www.laos-guide-999.com/images/Flag-of-LaoPDR.png"
                      />
                    </Row>
                  </div>
                </div>
              )}
            </SearchContainer>
          </ul>
        </div>
      </nav>
    </Container>
  );
};

const Flag = styled.img`
  width: 37px;
  height: 30px;
`;

const Icon = styled.img`
  margin-bottom: 2px;
  width: 43px;
  height: 35px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const HomeIcon = styled.img`
  margin-bottom: 2px;
  width: 27px;
  height: 25px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const Icon1 = styled.img`
  margin-bottom: 2px;
  width: 150px;
  height: 100px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const Icon4 = styled.img`
  margin-bottom: 20px;
  width: 200px;
  height: 100px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const TextImg = styled.img`
  margin-bottom: 2px;
  width: 170px;
  height: 80px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const Icon3 = styled.img`
  margin-bottom: 2px;
  width: 130px;
  height: 80px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const Logo = styled.img`
  width: 90px;
  height: 90px;
  padding: 10px;
  margin-top: 0px;
  @media only screen and (max-width: 900px) {
    margin-top: 15px;
    width: 65px;
    height: 65px;
  }
`;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
const Row = styled.div`
  margin-bottom: 5px;
  align-items: center;
  justify-content: start;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  &: hover {
    background-color: #eee;
  }
`;

const SearchContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  padding: 5px;
  border-radius: 10px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export default Navbar;
