import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";

const LeftList = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <Image
          alt=""
          src="https://img.freepik.com/free-vector/realistic-hexagonal-background_79603-1646.jpg?w=2000"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
          alt=""
          src="https://img.freepik.com/free-vector/realistic-hexagonal-background_79603-1646.jpg?w=2000"
        />
      </Carousel.Item>
    </Carousel>
  );
};

const RightList = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <Image
          alt=""
          src="https://img.freepik.com/free-vector/realistic-hexagonal-background_79603-1646.jpg?w=2000"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
          alt=""
          src="https://img.freepik.com/free-vector/realistic-hexagonal-background_79603-1646.jpg?w=2000"
        />
      </Carousel.Item>
    </Carousel>
  );
};

const CenterSilde = () => {
  return (
    <Container>
      <Left>
        <LeftList />
      </Left>
      <Right>
        <RightList />
      </Right>
    </Container>
  );
};

const Image = styled.img`
  width: 100%;
  height: auto;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Left = styled.div``;
const Right = styled.div``;
 
export default CenterSilde;
