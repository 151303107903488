import styled from "styled-components";
import Zoom from "react-reveal/Zoom";

const Product = (props) => {
  return (
    <Zoom>
      <Container>
        <Image src={props.ImageUrl} alt="" />
        <Text>{props.ProductName}</Text>
        {props.language === "EN" && <Text>Size: {props.Size}</Text>}
        {props.language === "LA" && <Text>ຂະໜາດ: {props.Size}</Text>}
        {props.delete && <button className="btn btn-danger w-100">ລົບ</button>}
      </Container>
    </Zoom>
  );
};

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  opacity: !isOpen ? "0" : "1";
  transition: "all .2s";
  visibility: !isOpen ? "hidden" : "visible";
  &:hover {
    background-color: #eeeeee;
  }
`;

const Image = styled.img`
  width: 320px;
  height: 300px;
  padding: 10px;
  @media only screen and (max-width: 860px) {
    width: 170px;
    height: 170px;
  }
`;

const Text = styled.label`
  font-size: 14px;
`;

export default Product;
