import Product from "./Product";
import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import BasedUrl from "../constrants";
import ProductsDetails from "../pages/ProductsDetails";
import { useLocation } from "react-router-dom";
const sideScroll = (element, step) => {
  element.scrollLeft += step;
};
function ProductsFooter(props) {
  const [productsList, setProductList] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [uuid, setUuid] = useState("");
  const [section, setSection] = useState(0);

  const location = useLocation();
  //const item = location.state.item;
  //  alert(item)
  // useEffect(() => {
  //   const location = useLocation();
  //   alert(location.state.item);
  // }, []);

  let item = "";
  if (location.state !== null) {
    item = location.state.item;
  }

  useEffect(() => {
    setTimeout(() => {
      setSection((c) => c + 700);
      sideScroll(contentWrapper.current, section);
      if (section > productsList.length * 100)
        setSection(-(productsList.length * 100) - 700);
    }, 5000);
  }, [section]);

  useEffect(() => {
    axios.get(BasedUrl + "/products/getAll").then((res) => {
      setProductList(res.data);
    });
  }, []);

  const contentWrapper = useRef(null);
  const omClosed = () => {
    setClicked(false);
  };

  return (
    <Container className=" p-0 m-0">
      {!clicked && item === "getAll" && (
        <Column>
          {productsList.map(
            (product) =>
              product.properties !== "" && (
                <div
                  key={product.uuid}
                  onClick={() => {
                    setClicked(true);
                    setUuid(product.uuid);
                  }}
                >
                  {props.language === "EN" && (
                    <Product
                      language={props.language}
                      key={product.uuid}
                      uuid={product.uuid}
                      ProductName={product.p_nameE}
                      ImageUrl={product.img1}
                      Size={product.size}
                    />
                  )}
                  {props.language === "LA" && (
                    <Product
                      language={props.language}
                      key={product.uuid}
                      uuid={product.uuid}
                      ProductName={product.p_name}
                      ImageUrl={product.img1}
                      Size={product.size}
                    />
                  )}
                </div>
              )
          )}
        </Column>
      )}
      {!clicked && item === "getAccessAll" && (
        <Column>
          {productsList.map(
            (product) =>
              product.properties === "" && (
                <div
                  key={product.uuid}
                  onClick={() => {
                    setClicked(true);
                    setUuid(product.uuid);
                  }}
                >
                  {props.language === "EN" && (
                    <Product
                      language={props.language}
                      key={product.uuid}
                      uuid={product.uuid}
                      ProductName={product.p_nameE}
                      ImageUrl={product.img1}
                      Size={product.size}
                    />
                  )}
                  {props.language === "LA" && (
                    <Product
                      language={props.language}
                      key={product.uuid}
                      uuid={product.uuid}
                      ProductName={product.p_name}
                      ImageUrl={product.img1}
                      Size={product.size}
                    />
                  )}
                </div>
              )
          )}
        </Column>
      )}
      {clicked && (
        <div className="mt-5">
          <div className="mt-5">
            <ProductsDetails
              language={props.language}
              Close={omClosed}
              uuid={uuid}
            />
          </div>
        </div>
      )}
    </Container>
  );
}

export default ProductsFooter;

const RowParent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  background-color: white;
  padding-bottom: 20px;
  width: 100%;
`;
const Row = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow: hidden;
  margin-bottom: 10px;
  scroll-behavior: smooth;
  box-sizing: border-box;
  margin: 0px;
  width: 100%;
  @media only screen and (max-width: 760px) {
    overflow: scroll;
  }
`;

const Column = styled.div`
  margin-top: 130px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  overflow-x: scroll;
  overflow: visible;
  width: 100%;
  scroll-behavior: smooth;
  @media only screen and (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ArrowLeft = styled.img`
  position: absolute;
  height: 60px;
  left: 40px;
  z-index: 1;
  border: 0;
  border-style: none;
  padding: 15px;
  border-radius: 90px;
  background-color: #2983e3;
  transform: scaleX(-1);
  @media only screen and (max-width: 760px) {
    display: none;
  }
`;

const ArrowEight = styled.img`
  right: 40px;
  position: absolute;
  height: 60px;
  z-index: 1;
  border: 0;
  border-style: none;
  padding: 15px;
  border-radius: 90px;
  background-color: #2983e3;
  @media only screen and (max-width: 760px) {
    display: none;
  }
`;
