import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ImageViewver = (props) => {
  const [index, setIndex] = useState();
  const [img, setImg] = useState("");
  const check = () => {
    switch (props.img) {
      case "/pic/1.png":
        setIndex(1);
        break;
      case "/pic/2.png":
        setIndex(2);
        break;
      case "/pic/3.png":
        setIndex(3);
        break;
      case "/pic/4.png":
        setIndex(4);
        break;
      case "/pic/5.png":
        setIndex(5);
        break;
      case "/pic/6.png":
        setIndex(6);
        break;
      case "/pic/7.png":
        setIndex(7);
        break;
      case "/pic/8.png":
        setIndex(8);
        break;
      case "/pic/9.png":
        setIndex(9);
        break;
      case "/pic/10.png":
        setIndex(10);
        break;
      case "/pic/11.png":
        setIndex(11);
        break;
      case "/pic/12.png":
        setIndex(12);
        break;
      case "/pic/13.png":
        setIndex(13);
        break;
      case "/pic/14.png":
        setIndex(14);
        break;
    }
  };

  useEffect(() => {
    check();
  }, []);

  useEffect(() => {
    setImg("/pic/" + index + ".png");
  }, [img, index]);

  const onBack = () => {
    if (index !== 1) setIndex((index) => index - 1);
  };
  const onNext = () => {
    if (index !== 14) setIndex((index) => index + 1);
  };
  return (
    <Container>
      <Close onClick={() => props.onClose()} src="/back.png" />
      <Contents>
        <ArrowLeft onClick={onBack} src="/back.png" />
        <Image src={img} />
        <ArrowEight onClick={onNext} src="/back.png" />
      </Contents>
    </Container>
  );
};

const Close = styled.img`
  width: 60px;
  height: 60px;
  position: fixed;
  left: 10px;
  top: 20px;
  border-radius: 90px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 17px;
  &:hover {
    background-color: #eeeeee;
  }
  @media only screen and (max-width: 700px) {
    left: -10px;
    top: 10px;
  }
`;
const ArrowLeft = styled.img`
  position: absolute;
  height: 60px;
  left: 40px;
  z-index: 1;
  border: 0;
  width: 60px;
  border-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 17px;
  border-radius: 90px;
  background-color: #fff;
  @media only screen and (max-width: 760px) {
    display: none;
  }
  &: hover {
    background-color: #eee;
  }
`;

const ArrowEight = styled.img`
  right: 40px;
  position: absolute;
  height: 60px;
  width: 60px;
  z-index: 1;
  transform: scaleX(-1);
  border: 0;
  border-style: none;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 17px;
  border-radius: 90px;
  background-color: #fff;
  @media only screen and (max-width: 760px) {
    display: none;
  }
  &: hover {
    background-color: #eee;
  }
`;

const Image = styled.img`
  height: 600px;
  @media only screen and (max-width: 1400px) {
    width: 700px;
    height: 450px;
  }
  @media only screen and (max-width: 999px) {
    width: 100%;
    height: 300px;
  }
`;

const Contents = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  height: auto;
  top: 0px;
  bottom: 0px;
  z-index: 10;
  max-height: 1000px;
  background-color: white;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
export default ImageViewver;
