import styled from "styled-components";

const Two = () => {
  return (
    <Container>
      <div className="container">
        <div className="row mt-4">
          <h5>ໄມ້ບົວຕົບແຕ່ງປະເພດໄມ້ທຽມໃຊ້ເຮັດຫຍັງໄດ້ແດ່?</h5>
        </div>
        <div className="row mt-2">
          <span>
            <span className="ps-5">
              ກ່ອນອື່ນໜົດພວກເຮົາມາຮູ້ຈັກໄມ້ບົວກັນກ່ອນ ໄມ້ບົວຈະເປັນແຜ່ນ,ທ່ອນ ຫຼື
            </span>
            ເສັ້ນໄມ້ ທີ່ເປັນເສັ້ນຍາວ ໂດຍມີຄວາມກວ້າງບໍ່ເກີນ 15cm.
            <br></br>
            ນິຍົມກັນນຳມາໃຊ້ຕົບແຕ່ງ ຫຼື ປົກປິດຕາມຮອຍຕໍ່ ຫຼື ຄວາມບໍ່ຮຽບຮອຍຂອງເຮືອນ
            ພ້ອມທັ້ງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນອີກດ້ວຍ
          </span>
        </div>
        <div className="row">
          <span>
            ໄມ້ບົວແມ່ນປະກອບມີ 7 ປະເພດຄື : ບົວພື້ນ, ບົວເພດານ, ບົວວົງກົບປະຕູ,
            ບົວວົງກົບປ່ອງຢ້ຽມ, ບົວປົກ, ບົວຕົບແຕ່ງ ແລະ ບົວປິດ.
            <br></br>
            ໄມ້ບົວແຕ່ລະປະເພດຈະມີຂະໜາດຄວາມກ້ວາງຕ່າງກັນແຕ່ລວງຍາວເທົ່າກັນ 3m
            ມີສີສັນເປັນເອກະລັກ ແລະ ມີຄວາມງາມເປັນຈຸດເດັ່ນຄືກັນ.
            <br></br>
            ເປັນວັດສະດຸທີ່ເໝາະສົມໃນການຕົບແຕ່ງພາຍໃນ.
          </span>
        </div>
        <div className="row mt-4">
          <h5>ວັດສະດຸຂອງເຮົາແມ່ນຜະລິດມາຈາກຫຍັງ?</h5>
        </div>
        <div className="row mt-2">
          <span>
            <span className="ps-5">
              ຜະລິດມາຈາກຝຸ່ນໄມ້ ແລະ ໄຍໄມ້ໄຜ່ປະສົມກັບຢາງ PVC ເຊິ່ງຫຍໍ້ມາຈາກ
              (POLYVINYLL CHLORIDE) ເປັນພາສຕິກແບບດຽວ
            </span>
            <br></br>
            ກັນກັບກ່ອງທີ່ໃຊ້ບັນຈຸອາຫານ ເປັນພາສຕິກທີ່ບໍ່ກໍ່ໃຫ້ເກີດສານພິດ
          </span>
        </div>

        <div className="row mt-4">
          <h5>ຂັ້ນຕອນການຜະລິດວັດສະດຸເປັນແນວໃດ?</h5>
        </div>
        <div className="row mt-2">
          <span>
            <span className="ps-5">
              ວັດສະດຸແມ່ນຜະລິດໂດຍການນໍາໃຊ້ເຕັກນິກແບບອັດເຂົ້າຮູບດ້ວຍວິທີການໜີບເຢັນ
            </span>
            ບໍ່ມີສ່ວນຜະສົມຂອງກາວໃນການຜະລິດ ຈຶງເຮັດໃຫ້ບໍ່ມີສານເມຕານ່ານ
            <br></br>
            ລະບໍ່ສົ່ງຜົນອັນຕະລາຍຕໍ່ຜູ້ອາໃສ ແລະ ເປັນມິດຕໍ່ສິ່ງແວດລ້ອມ
          </span>
        </div>
        <div className="row d-flex justify-content-center">
          <img className="w-75 ps-5 pe-5" src="/mixedl.png" />
        </div>
        <div className="row mt-4">
          <h5>ຈຸດເດັ່ນຂອງວັດສະດຸ</h5>
        </div>
        <div className="row">
          <span>
            <span className="ps-5">
              ແມ່ນປວກບໍ່ກິນ, ກັນນ້ຳ, ບໍ່ຂຶ້ນເຊື້ອຣາ, ບໍ່ຕິດໄຟລາມໄຟ,
            </span>
            ອາຍຸການໃຊ້ງານຫຼາຍກວ່າ 20 ປີ ແລະ ສະດວກໃນການຕິດຕັ້ງ
            ຮັກສາຄວາມສະອາດງ່າຍ.
            <br></br>
            ທົນທານຕໍ່ອາຍຸການໃຊ້ງານໄດ້ດີເປັນມິດຕໍ່ຜູ້ຢູ່ອາໄສ ແລະ ສິ່ງແວດລ້ອມ.
          </span>
        </div>
        <div className="row mt-4">
          <h5>ຄຸນສົມບັດຂອງວັດສະດຸ</h5>
        </div>
        <div className="row d-flex justify-content-center">
          <img className="w-75 ps-5 pe-5" src="/propl.png" />
        </div>
        <div className="row mt-4">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວພື້ນ</h5>
        </div>
        <div className="row ps-5">
          <span>
            ໄມ້ບົວພື້ນມີຮູບຊົງເປັນແຜ່ນໄມ້ເສັ້ນຍາວມີໜ້າແບບບົວແປທີ່ເໝາະກັບການຕິດແນ້ບເຂົ້າກັບຝາ.
            <br></br>
          </span>
        </div>
        <div className="row d-flex justify-content-center">
          <Im src="/2/ໄມ້ບົວພື້ນ/01.png" />
        </div>
        <span> ມີຂະໜາດ : ໜ້າກວ້າງ10cm ຍາວ3M ໜາ1,5 cm</span>
        <div className="row d-flex mb-5 justify-content-center">
          <img className="w-50 ps-5 pe-5" src="/2/ໄມ້ບົວພື້ນ/ບົວພື້ນ.png" />
        </div>
        <div className="row mt-4">
          <h5>ການນຳໃຊ້ໄມ້ບົວພື້ນ</h5>
        </div>
        <div className="row">
          <span>
            <span className="ps-5">
              ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວພື້ນ ແມ່ນເໝາະໃຊ້ໄວ້ປິດຮອຍຕໍ່ ຫຼື
            </span>
            ປິດຄວາມບໍ່ຮຽບຮ້ອຍລະວ່າງພື້ນກັບຝາ ຫຼື ຂອບລູກຂັ້ນໄດ
            <br></br>
            ພ້ອມທັ້ງຍັງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
          </span>
        </div>

        <div className="row mt-4 ps-5 mb-5">
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວພື້ນ/1.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວພື້ນ/3.jpg" />
          </Row>
        </div>

        <div className="row mt-4">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວເພດານ</h5>
          <br></br>
          <div className="ps-5">
            <span>
              ໄມ້ບົວເພດານມີຮູບຊົງທ່ອນໄມ້ເສັ້ນຍາວ ມີໜ້າແບບ
              ເປັນບົວອຽງທີ່ເໝາະກັບການຕິດລະວ່າງເພດານກັບຝາ.
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Im src="/2/ໄມ້ບົວເພດານ/01.png" />
        </div>
        <div className="row mt-3">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 10cm ຍາວ 3M ໜາ 2 cm</span>
        </div>
        <div className="row mt-3 d-flex mb-5 justify-content-center">
          <img
            className="ii mb-3 ps-5 pe-5"
            src="/2/ໄມ້ບົວເພດານ/ບົວເພດານ.png"
          />
        </div>
        <div className="row">
          <h5>ການນຳໃຊ້ໄມ້ບົວເພດານ</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວເພດານ ແມ່ນເໝາະໃຊ້ໄວ້ປິດຮອຍຕໍ່ຫຼື
              </span>
              ປິດຄວາມບໍ່ຮຽບຮ້ອຍລະວ່າງເພດານກັບຝາ
              <br></br>
              ພ້ອມທັ້ງຍັງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວເພດານ/1.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວເພດານ/2.jpg" />
          </Row>
        </div>
        <div className="row mt-5">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວວົງກົບປະຕູ</h5>
          <br></br>
          <div className="ps-5">
            <span>
              ໄມ້ບົວວົງກົບປະຕູ ມີຮູບຊົງເປັນບົວທີ່ມີຮູບຊົງແບບທ່ອນໄມ້ເສັ້ນຍາວ
              ມີໜ້າແບບ Classic. ໃຫ້ຄວາມຮູ້ສຶກຄືກັນກັບເອົາໄມ້ແທ້ມາຕົບແຕ່ງ.
            </span>
          </div>
        </div>

        <div className="row mt-3 row d-flex justify-content-center">
          <Im src="/2/ໄມ້ບົວປະຕູ/01.png" />
        </div>
        <div className="row">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 8cm ຍາວ 3M ໜາ 2 cm</span>
        </div>
        <div className="row mb-5 row d-flex justify-content-center">
          <img className="w-25 ps-5 pe-5" src="/2/ໄມ້ບົວປະຕູ/ບົວປະຕູ.png" />
        </div>

        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ບົວວົງກົບປະຕູ</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວວົງກົບປະຕູ ແມ່ນເໝາະໃຊ້ໄວ້ປິດຮອຍຕໍ່ ຫຼື
              </span>
              ປິດຄວາມບໍ່ຮຽບຮ້ອຍລະວ່າງເພດານກັບຝາ
              <br></br>
              ພ້ອມທັ ງຍັງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ
            </span>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-4">
          <Row className="px-5">
            <img className="ii" src="/2/ໄມ້ບົວປະຕູ/3.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວປະຕູ/2.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວປະຕູ/1.jpg" />
          </Row>
        </div>

        <div className="row mt-5">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວວົງກົບປ່ອງຢ້ຽມ</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5">
                ໄມ້ບົວວົງກົບປ່ອງຢ້ຽມ ມີຮູບຊົງເປັນບົວທີ່ມີຮູບຊົງທ່ອນໄມ້ເສັ້ນຍາວ
                ມີໜ້າແບບ Classic ໃຫ້ຄວາມຮູ້ສຶກຄືກັນກັບເອົາໄມ້ແທ້ມາຕົບແຕ່ງ.
              </span>
            </span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <Im src="/2/ໄມ້ບົວປ່ອງຢ້ຽມ/01.png" />
        </div>
        <div className="row ps-5">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 6cm ຍາວ 3M ໜາ 2 cm</span>
        </div>
        <div className="row my-3 row d-flex justify-content-center">
          <img className="w-25" src="/2/ໄມ້ບົວປ່ອງຢ້ຽມ/ບົວ.png" />
        </div>
        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ບົວວົງກົບປ່ອງຢ້ຽມ</h5>
          <br></br>
          <div className="ps-5">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວປ່ອງຢ້ຽມ
                ແມ່ນເໝາະໃຊ້ຕົບແຕ່ງວົງກົບປ່ອງຢ້ຽມ,ຂ
              </span>
              ອບເທິງກັນເປື້ອນແບບສູງ, ຂອບໂຕະ
              <br></br>
              ຫຼື ຂອບຮູບພາບທີ່. ພ້ອມເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ
              ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-3 mb-5">
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວປ່ອງຢ້ຽມ/6.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວປ່ອງຢ້ຽມ/4.jpg" />
          </Row>
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວປ່ອງຢ້ຽມ/3.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວປ່ອງຢ້ຽມ/1.jpg" />
          </Row>
        </div>

        <div className="row mt-5">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວວົງກົບປົກ</h5>
          <br></br>
          <div className="ps-5">
            <span>
              ມີຮູບຊົງເປັນບົວທີ່ມີຮູບຊົງທ່ອນໄມ້ເສັ້ນຍາວ ມີໜ້າແບບ Classic.
              ໃຫ້ຄວາມຮູ້ສຶກຄືກັນກັບເອົາໄມ້ແທ້ມາຕົບແຕ່ງ.
            </span>
          </div>
        </div>
        <div className="row row mt-3 d-flex justify-content-center">
          <Im src="/2/ໄມ້ບົວປົກ/01.png" />
        </div>
        <div className="row">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 7,5cm ຍາວ 3M ໜາ 3,5cm</span>
        </div>
        <div className="row my-3 row d-flex justify-content-center">
          <img className="w-25" src="/2/ໄມ້ບົວປົກ/ບົວປົກ.png" />
        </div>
        <div className="row mt-5 pt-4">
          <h5>ການນຳໃຊ້ໄມ້ບົວວົງກົບປົກ</h5>
          <br></br>
          <div>
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວປົກ ແມ່ນເໝາະໃຊ້ຕົບແຕ່ງວົງກົບປະຕູ,
              </span>
              ວົງກົບປ່ອງຢ້ຽມ,ຂອບເທິງກັນເປື້ອນແບບສູງ, ຂອບໂຕະ ຫຼື ຂອບຮູບພາບ.
              <br></br>
              ພ້ອມເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວປົກ/1.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວປົກ/2.jpg" />
          </Row>
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວປົກ/3.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວປົກ/4.png" />
          </Row>
        </div>
        <div className="row mt-5">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວຕົບແຕ່ງ</h5>
          <br></br>
          <div className="ps-5">
            <span>
              ມີຮູບຊົງເປັນບົວທີ່ມີຮູບຊົງເສັ້ນໄມ້ຍາວ ມີໜ້າແບບ Classic.
              ໃຫ້ຄວາມຮູ້ສຶກຄືກັນກັບເອົາໄມ້ແທ້ມາຕົບແຕ່ງ
            </span>
          </div>
        </div>
        <div className="row row mt-3 d-flex justify-content-center">
          <img className="w-50" src="/2/ໄມ້ບົວຕົບແຕ່ງ/01.png" />
        </div>
        <div className="ps-5 row">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 3,5cm ຍາວ 3M ໜາ 2cm </span>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <img className="w-25" src="/2/ໄມ້ບົວຕົບແຕ່ງ/ບົວຕົບແຕ່ງ.png" />
        </div>

        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ບົວຕົບແຕ່ງ</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5"></span>
              ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວຕົບແຕ່ງ
              ແມ່ນເໝາະໃຊ້ຕົບແຕ່ງກັນເປື້ອນສູງແບບປາໂນ່, ຕົບແຕ່ງຝາ, ເພດານ ແລະ
              <br></br>
              ເຮັດເປັນຂອບຮູບພາບ. ພ້ອມເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ
              ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວຕົບແຕ່ງ/1.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວຕົບແຕ່ງ/6.jpg" />
          </Row>
          <Row>
            <img className="ii" src="/2/ໄມ້ບົວຕົບແຕ່ງ/3.jpg" />
            <img className="ii" src="/2/ໄມ້ບົວຕົບແຕ່ງ/4.jpg" />
          </Row>
        </div>

        <div className="row mt-5">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ບົວປິດ(L) ຫຼື (V) </h5>
          <br></br>
          <div className="ps-5">
            <span>
              ມີຮູບຊົງເປັນບົວທີ່ມີຮູບຊົງທ່ອນໄມ້ຍາວ ເປັນຮູບໂຕ L ຫຼື V
              ເປັນຮູບຮ່າງທີ່ເໝາະກັບຕິດກັບຫຼ່ຽມ.
            </span>
          </div>
        </div>
        <div className="mt-3 row d-flex justify-content-center">
          <Im src="/2/ບົວL/01.png" />
        </div>
        <div className="row ps-5">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 3,5cm ຍາວ 3M ໜາ 2cm</span>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <img className="w-25" src="/2/ບົວL/ບົວ-L.png" />
        </div>
        <div className="row mt-5 pt-5">
          <h5>ການນຳໃຊ້ໄມ້ບົວບົວປິດ(L) ຫຼື (V)</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ບົວປິດ
                ແມ່ນເໝາະໃຊ້ປິດຈົບສົນເພື່ອປິດຄວາມບໍ່ຮຽບຮ້ອຍຂອງແຜ່ນໄມ້ທຽມ,
              </span>
              <br></br>
              ປິດຈົບສົນແຜ່ນໄມ້ລະແນງ, ປິດຕາມຫຼ່ຽມເສົາ ແລະ ຕາມຂອບຕູ້ ຫຼື ໂຕະ
            </span>
          </div>
        </div>

        <div className="row mt-3">
          <Row>
            <img className="ii" src="/2/ບົວL/1.jpg" />
            <img className="ii" src="/2/ບົວL/6.jpg" />
          </Row>
          <Row>
            <img className="ii" src="/2/ບົວL/3.jpg" />
            <img className="ii" src="/2/ບົວL/2.jpg" />
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Two;

const Im = styled.img`
  width: 8 0%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  background-color: white;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  max-width: 1500px;
  //overflow: hidden;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
  @media only screen and (max-width: 860px) {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
