import styled from "styled-components";
const Three = () => {
  return (
    <Container>
      <div className="container">
        <div className="row mt-4">
          <h5>ໄມ້ລະແນງປະເພດໄມ້ທຽມໃຊ້ເຮັດຫຍັງໄດ້ແດ່?</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5">
                ກ່ອນອື່ນໜົດພວກເຮົາມາຮູ້ຈັກໄມ້ລະແນງກັນກ່ອນ
                ໄມ້ລະແນງຈະເປັນວັດສະດຸໄມ້ທີ່ເປັນເສັ້ນລຽວຍາວ
              </span>
              <br></br>
              ເອົາມາວາງລຽນຕໍ່ກັນໂດຍມີໄລຍະຫ່າງຊ່ອງວ່າງເທົ່າໆກັນ
              ນິຍົມກັນນຳມາໃຊ້ຕົບແຕ່ງ ຝາ,ເພດານ,ຂັ້ນຫ້ອງ ຫຼື
              <br></br>
              ລະແນງບັງສາຍຕາເປັນຕົ້ນ.
            </span>
            <br></br>
            <span>
              <span className="ps-5">
                ໄມ້ລະແນງແມ່ນປະກອບມີ 4 ປະເພດຄື : ລະແນງໜ້າ 18, ລະແນງໜ້າ 20,
                ລະແນງຕັ້ງ ແລະ ລະແນງເພດານ,
              </span>
              <br></br>
              ໄມ້ລະແນງແຕ່ລະປະເພດຈະມີຂະໜາດຄວາມກ້ວາງຕ່າງກັນແຕ່ລວງຍາວເທົ່າກັນ 3m
              ມີສີສັນເປັນເອກະລັກ
              <br></br>
              ແລະ ມີຄວາມງາມເປັນຈຸດເດັ່ນຄືກັນ.
              ເປັນວັດສະດຸທີ່ເໝາະສົມໃນການຕົບແຕ່ງພາຍໃນ.
            </span>
          </div>

          <br className="mt-3"></br>
          <h5>ວັດສະດຸຂອງເຮົາແມ່ນຜະລິດມາຈາກຫຍັງ?</h5>
          <br></br>
          <div className="">
            <span>
              <span className="ps-5">
                ຜະລິດມາຈາກຝຸ່ນໄມ້ ແລະ ໄຍໄມ້ໄຜ່ປະສົມກັບຢາງ PVC ເຊິ່ງຫຍໍ້ມາຈາກ
                (POLYVINYLL CHLORIDE)
              </span>
              <br></br>
              ເປັນພາສຕິກແບບດຽວກັນກັບກ່ອງທີ່ໃຊ້ບັນຈຸອາຫານ
              ເປັນພາສຕິກທີ່ບໍ່ກໍ່ໃຫ້ເກີດສານພິດ
            </span>
          </div>

          <br className="mt-3"></br>
          <h5>ຈຸດເດັ່ນຂອງວັດສະດຸ</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ແມ່ນປວກບໍ່ກິນ, ກັນນ້ຳ, ບໍ່ຂຶ້ນເຊື້ອຣາ, ບໍ່ຕິດໄຟລາມໄຟງ່າຍ,
                ອາຍຸການໃຊ້ງານຫຼາຍກວ່າ 20 ປີແລະ ສະດວກໃນການຕິດຕັ້ງ
              </span>
              <br></br>
              ຮັກສາຄວາມສະອາດງ່າຍ.
              ທົນທານຕໍ່ອາຍຸການໃຊ້ງານໄດ້ດີເປັນມິດຕໍ່ຜູ້ຢູ່ອາໄສ ແລະ ສິ່ງແວດລ້ອມ.
            </span>
          </div>
          <br className="mt-3"></br>
          <h5>ຄຸນສົມບັດຂອງວັດສະດຸ</h5>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <img className="w-75 mb-4" src="/propl.png" />
          <br className="mt-5"></br>
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ລະແນງ 18</h5>
          <div>
            <span>
              <span className="ps-5">
                ໄມ້ລະແນງ18ມີຮູບຊົງເປັນແຜ່ນລ່ອງໄມ້4ລ່ອງເສັ້ນຍາວ
                ໂດຍມີຊ່ອງວ່າງແຕ່ລະລ່ອງເທົ່າໆກັນ, ທາງດ້ານຂ້າງຂອງ
              </span>
              <br></br>
              ແຜ່ນລະແນງຈະເປັນລະບົບຄລິກລ໋ອກ ເພື່ອງ່າຍໃນການຕິດຕັ້ງ.
            </span>
          </div>
        </div>
        <div className="row mt-5 row mt-3 d-flex justify-content-center">
          <Im src="/3/ໄມ້ລະແນງ18/01.png" />
          <br className="mt-3"></br>
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ18cm ຍາວ 3M ໜາຂອງລູກຄື້ນ 2 cm</span>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <img className="w-50 mb-3" src="/3/ໄມ້ລະແນງ18/ລະແນງ18.png" />
        </div>
        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ລະແນງ 18</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ລະແນງໜ້າ 18
                ແມ່ນເໝາະໃຊ້ໄວ້ຕົບແຕ່ງຝາ,ຕິດເຄົ້າເຕີ້,ກັນເປື້ຶອນແບບສູງ, ເພດານ ຫຼື
              </span>
              <br></br>
              ຕິດຕົບແຕ່ງເສົາ ພ້ອມທັງຍັງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ
              ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <Row className="mt-3">
            <img className="ii ps-5" src="/3/ໄມ້ລະແນງ18/1.jpg" />
            <img className="ii pe-5" src="/3/ໄມ້ລະແນງ18/2.jpg" />
          </Row>
          <Row>
            <img className="ii ps-5" src="/3/ໄມ້ລະແນງ18/3.jpg" />
            <img className="ii pe-5" src="/3/ໄມ້ລະແນງ18/5.jpg" />
          </Row>
          <Row></Row>
        </div>
        <div className="row mt-5">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ລະແນງ 20</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ໄມ້ລະແນງ20ມີຮູບຊົງເປັນແຜ່ນລ່ອງໄມ້ 3 ລ່ອງສັ້ນຍາວ
                ໂດຍມີຊ່ອງວ່າງແຕ່ລະລ່ອງເທົ່າໆກັນ,
              </span>
              <br></br>
              ທາງດ້ານຂ້າງຂອງແຜ່ນລະແນງຈະເປັນລະບົບຄລິກລ໋ອກ ເພື່ອງ່າຍໃນການຕິດຕັ້ງ
              ຮູບຊົງຈະຄ້າຍຄືກັບລະແນງ 18
              <br></br>
              ຕ່າງແຕ່ວ່າລະແນງ20ຈະມີຂະໜາດໃຫຍ່ກ່ອນ, ບາງກ່ອນແລະ ມີ 3 ລ່ອງ.
            </span>
          </div>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <Im className="" src="/3/ໄມ້ລະແນງ20/01.png" />
        </div>
        <div className="row">
          <span>ມີຂະໜາດ : ໜ້າກວ້າງ 20cm ຍາວ 3M ໜາ 1,5 cm </span>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <img className="w-50 mb-3" src="/3/ໄມ້ລະແນງ20/ລະແນງ-20.png" />
        </div>
        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ລະແນງ 20</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ລະແນງໜ້າ 20
                ແມ່ນເໝາະໃຊ້ໄວ້ຕົບແຕ່ງຝາ,ຕິດເຄົ້າເຕີ້,ກັນເປື້ຶອນແບບສູງ,ເພດານ ຫຼື
              </span>
              <br></br>
              ຕິດຕົບແຕ່ງເສົາ ພ້ອມທັງຍັງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ
              ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-4">
          <Row>
            <img className="ii " src="/3/ໄມ້ລະແນງ20/1.jpg" />
            <img className="ii " src="/3/ໄມ້ລະແນງ20/2.jpg" />
            <img className="ii " src="/3/ໄມ້ລະແນງ20/3.jpg" />
          </Row>
          <Row></Row>
          <br className="mt-5"></br>
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ລະແນງຕັ້ງ</h5>
          <span>
            ໄມ້ລະແນງຕັ້ງມີຮູບຊົງເປັນທ່ອນໄມ້ 4 ລ່ຽມເສັ້ນຍາວ
            ໂດຍມີຮູບຊົງຄ້າຍຄືທ່ອນໄມ້ແທ້ ແລະ ມີຫຼາຍສີໃຫ້ຊົມໃຊ້
          </span>
        </div>
        <div className="row mt-3 row  d-flex justify-content-center">
          <img className="w-50" src="/3/ໄມ້ລະແນງຕັ້ງ/01.png" />
        </div>
        <span>ມີຂະໜາດ : ໜ້າກວ້າງ 10cm ຍາວ 3M ໜາ 5 cm</span>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <img className="ii1" src="/3/ໄມ້ລະແນງຕັ້ງ/ລະແນງ5.png" />
        </div>
        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ລະແນງຕັ້ງ</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ລະແນງຕັ້ງ ແມ່ນເໝາະໃຊ້ໄວ້ຕົບແຕ່ງຝາ,
                ຂັ້ນຫ້ອງເພື່ອບັງສາຍຕາ,ເພດານ ຫຼື ຕິດຕົບແຕ່ງເສົາ
              </span>
              <br></br>
              ພ້ອມທັງຍັງເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ ແລະ ມີມິຕິຫຼາຍຂຶ້ນອີກດ້ວຍ.
            </span>
          </div>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <Im className="" src="/3/ໄມ້ລະແນງຕັ້ງ/1.jpg" />
        </div>
        <div className="mt-5 row">
          <h5>ຮູບຮ່າງລັກສະນະຂອງໄມ້ລະແນງເພດານ</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ໄມ້ລະແນງຕັ້ງມີຮູບຊົງເປັນທ່ອນໄມ້ 4 ລ່ຽມເສັ້ນຍາວ
                ທາງດ້ານຫຼັງຂອງທ່ອນໄມ້ຈະມີຊ່ອງວ່າງທີ່ເປັນຂໍເພື່ອເກາະກັນເຫຼັກຊີລາຍ
              </span>
              <br></br>
              ໂດຍມີຮູບຊົງຄ້າຍຄືທ່ອນໄມ້ແທ້ ມີຫຼາຍສີໃຫ້ເລືອກໃຊ້ຕົບແຕ່ງ.
            </span>
          </div>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <Im src="/3/ໄມ້ລະແນງເພດານ/01.png" />
        </div>
        <span className="ps-5">ມີຂະໜາດ : ໜ້າກວ້າງ 10cm ຍາວ 3M ໜາ 4 cm</span>
        <div className="row  row mt-5 d-flex justify-content-center">
          <img className="w-25" src="/3/ໄມ້ລະແນງເພດານ/ລະແນງ4.png" />
        </div>
        <div className="row mt-5">
          <h5>ການນຳໃຊ້ໄມ້ລະແນງເພດານ</h5>
          <div className="">
            <span>
              <span className="ps-5">
                ສຳລັບວິທີການນຳໃຊ້ໄມ້ລະແນງປະເພດນີ້
                ແມ່ນເໝາະໃຊ້ໄວ້ຕົບແຕ່ງເພດານໂດຍສະເພາະ ເພີ່ມຄວາມສວຍງາມໃຫ້ເຮືອນ
              </span>
              <br></br>
              ແລະ ເຮັດໃຫ້ເຮືອນມີມິຕິ ແລະ ທັນສະໄໝຫຼາຍຂຶ້ນກວ່າເກົ່າ.
            </span>
          </div>
        </div>
        <div className="row mt-3 row mt-3 d-flex justify-content-center">
          <Row>
            <img className="ii" src="/3/ໄມ້ລະແນງເພດານ/1.jpg" />
            <img className="ii" src="/3/ໄມ້ລະແນງເພດານ/2.jpg" />
          </Row>
        </div>
      </div>
    </Container>
  );
};
export default Three;

const Im = styled.img`
  width: 80%;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
const Container = styled.div`
  display: flex;
  background-color: white;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  max-width: 1500px;
  //overflow: hidden;
  align-items: start;
  align-content: start;
  justify-content: start;
  width: 100%;
  margin-bottom: 50px;
  @media only screen and (max-width: 860px) {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
