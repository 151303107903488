import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../../constrants";

const AddData = async (data) => {
  await axios
    .post(BasedUrl + "/install/add", data)
    .then((res) => {
      alert("ເພີ່ມສຳເລັດ");
    })
    .catch((err) => alert(err));
};

const InstallAdmin = () => {
  const [img, setImg] = useState("");
  const [imgE, setImgE] = useState("");

  const [title, setTItle] = useState("");
  const [titleE, setTItleE] = useState("");
  const [data, setData] = useState({});
  const onAdd = () => {
    setData({
      img,
      title,
      titleE,
      imgE,
    });
  };

  useEffect(() => {
    if (data.title) AddData(data);
  }, [data]);

  return (
    <div className="container">
      <Image className="my-3 img-fluid rounded" src={img} />

      <div className="d-flex justify-content-center row">
        <input
          onChange={(e) => setImg(e.target.value)}
          className="form-control mb-3 text-center w-25"
          placeholder="ລິງຮູບພາບ"
          value={img}
        />
      </div>
      <Image className="my-3 img-fluid rounded" src={imgE} />
      <div className="d-flex justify-content-center row">
        <input
          onChange={(e) => setImgE(e.target.value)}
          className="form-control mb-3 text-center w-25"
          placeholder="ລິງຮູບພາບພາສາອັງກິດ"
          value={imgE}
        />
      </div>
      <div className="d-flex justify-content-center row">
        <input
          onChange={(e) => setTItle(e.target.value)}
          className="form-control mb-3 text-center w-25"
          placeholder="ຫົວຂໍ້"
          value={title}
        />
      </div>
      <div className="d-flex justify-content-center row">
        <input
          onChange={(e) => setTItleE(e.target.value)}
          className="form-control mb-3 text-center w-25"
          placeholder="ຫົວຂໍ້ພາສາອັງກິດ"
          value={titleE}
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <button onClick={onAdd} className="btn btn-primary w-25">
          ເພີ່ມ
        </button>
      </div>
    </div>
  );
};

const Image = styled.img`
  width: 400px;
  height: 650px;
`;
export default InstallAdmin;
