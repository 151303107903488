import styled from "styled-components";
import Viewer from "react-viewer";
import axios from "axios";
import { useEffect, useState } from "react";
import BasedUrl from "../constrants";
import Zoom from "react-reveal/Zoom";

const UsingDetail = (props) => {
  const [selectedImg, setSelectedImg] = useState(props.uimg1);
  const [visible, setVisible] = useState(false);

  const [productDetail, setProductDetail] = useState({});

  const uuid = props.uuid;
  useEffect(() => {
    try {
      axios
        .post(BasedUrl + "/products/getOne", { uuid: uuid })
        .then((res) => {
          setProductDetail(res.data[0]);
          setSelectedImg(res.data[0].uimg1);
        })
        .catch((error) => {});
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <div className="container-fluid bg-white">
      <div className="container">
        <Icon
          onClick={() => props.Back()}
          src="https://icon-library.com/images/back-arrow-icon/back-arrow-icon-9.jpg"
        />
      </div>
      <Zoom>
        <Row>
          <div className="col">
            <h3>ການຕິດຕັ້ງ {productDetail.p_name}</h3>
          </div>
          <div class="row">
            <div class="col-sm">
              <div>
                <Viewer
                  onMaskClick={() => setVisible(false)}
                  visible={visible}
                  onClose={() => {
                    setVisible(false);
                  }}
                  images={[
                    {
                      src: selectedImg,
                      alt: "",
                    },
                  ]}
                />
              </div>
              <Image
                onClick={() => {
                  setVisible(true);
                }}
                alt=""
                src={selectedImg}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SmallImage
                onClick={() => setSelectedImg(productDetail.uimg1)}
                alt=""
                src={productDetail.uimg1}
              />
              <SmallImage
                onClick={() => setSelectedImg(productDetail.uimg2)}
                alt=""
                src={productDetail.uimg2}
              />
              <SmallImage
                onClick={() => setSelectedImg(productDetail.uimg3)}
                alt=""
                src={productDetail.uimg3}
              />
              <SmallImage
                onClick={() => setSelectedImg(productDetail.uimg4)}
                alt=""
                src={productDetail.uimg4}
              />
            </div>
          </div>
          <div className="row text-center m-3">
            <label>ລາຍລະອຽດການນຳໃຊ້</label>
            <span>{productDetail.using}</span>
          </div>
        </Row>
      </Zoom>
    </div>
  );
};

// const Container = styled.div`
//   padding-top: 30px;
//   background-color: white;
//   margin-top: 80px;
//   display: flex;
//   flex-direction: column;\
// `;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

const SmallImage = styled.img`
  width: 120px;
  height: 110px;
  padding: 10px;
  &: hover {
    background-color: #eee;
  }
  @media only screen and (max-width: 860px) {
    width: 70px;
    height: 60px;
  }
`;

const Image = styled.img`
  width: 700px;
  height: 580px;
  padding: 20px;
  @media only screen and (max-width: 860px) {
    width: 700px;
    height: 400px;
  }
  @media only screen and (max-width: 760px) {
    width: 600px;
    height: 350px;
  }
  @media only screen and (max-width: 660px) {
    width: 400px;
    height: 250px;
  }
`;

const Icon = styled.img`
  padding: 10px;
  margin-top: 50px;
  width: 60px;
  height: 60px;
  &: hover {
    background-color: #eee;
  }
`;
export default UsingDetail;
