import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../../constrants";

function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

const deleteMasterpiece = async (uuid) => {
  await axios
    .delete(BasedUrl + "/masterpieces/deleteOne", { data: { uuid } })
    .then((res) => {
      alert("ລົບແບນເນີ້ສຳເລັດ");
    })
    .catch((error) => {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ: \n" + error);
    });
};

const addMasterpiece = async (data) => {
  try {
    await axios.post(BasedUrl + "/masterpieces/add", data).then((res) => {
      alert("ເພີ່ມບົດຄວາມສຳເລັດ");
    });
  } catch (error) {
    alert("ເກີດບັນຫາໃນການເພີ່ມ ກະລູນາລອງໃໝ່: \n" + error);
  }
};

const MasterpieceAdmin = () => {
  const [title, setTitle] = useState("");
  const [title1, setTitle1] = useState("");
  const [titleE, setTitleE] = useState("");
  const [titleE1, setTitleE1] = useState("");
  const [info, setInfo] = useState("");
  const [info1, setInfo1] = useState("");
  const [infoE, setInfoE] = useState("");
  const [infoE1, setInfoE1] = useState("");
  const [data, setDta] = useState([]);

  const [image, setImage] = useState();

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/masterpieces/getAll").then((res) => {
        setDta(res.data);
        setTrigger(false);
      });
    } catch (error) {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ");
    }
  }, [trigger]);

  useEffect(() => {
    if (info1 !== "" && title1 != "") {
      addMasterpiece({
        info: info1,
        title: title1,
        titleE: titleE1,
        infoE: infoE1,
        image: image
      });
    }
  }, [info1, infoE1, title1, titleE1]);

  return (
    <div className="col mt-5 text-center">
      <div className="col mt-5 text-center">
        <div className="bg-white p-5 mb-5">
          <img
            className="w-50"
            src={image}
          />
          <div className="d-flex mt-4 justify-content-center">
            <input
              onChange={(e) => setImage(e.target.value)}
              className="form-control mb-3 text-center w-25"
              placeholder="ຣູບພາບ"
              value={image}
            />
          </div>

          <div className="d-flex justify-content-center row">
            <input
              onChange={(e) => setTitle(e.target.value)}
              className="form-control mb-3 text-center w-25"
              placeholder="ຫົວຂໍ້ຜົນງານ"
              value={title}
            />
            <input
              onChange={(e) => setTitleE(e.target.value)}
              className="form-control mb-3 text-center w-25"
              placeholder="ຫົວຂໍ້ຜົນງານພາສາອັງກິດ"
              value={titleE}
            />
            <textarea
              onChange={(e) => setInfo(e.target.value)}
              value={info}
              class="form-control text-center mb-3"
              rows="4"
              placeholder="ລາຍລະອຽດຜົນງານ"
            ></textarea>
            <textarea
              onChange={(e) => setInfoE(e.target.value)}
              value={infoE}
              class="form-control text-center mb-3"
              rows="4"
              placeholder="ລາຍລະອຽດຜົນງານພາສາອັງກິດ"
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                setTitle1(title);
                setTitleE1(titleE);
                setInfo1(info);
                setInfoE1(infoE);
                setTrigger(true);
              }}
              className="btn btn-primary w-25"
            >
              ເພິ່ມ
            </button>
          </div>
        </div>
      </div>

      <h3>ລາຍການຜົນງານ</h3>
      <List>
        {data.map((d) => (
          <Masterpieces key={d.uuid}>
            <h5>{d.title}</h5>
            <Description>{d.info}</Description>
            <div>
              <button
                onClick={() => {
                  if (
                    window.confirm("ທ່ານຕ້ອງການລົບສິນຄ້ານີ້ແທ້ບໍ່?") === true
                  ) {
                    deleteMasterpiece(d.uuid);
                    setTrigger(true);
                  }
                }}
                className="btn btn-danger w-50 mt-5 mb-4"
              >
                ລົບ
              </button>
            </div>
          </Masterpieces>
        ))}
      </List>
    </div>
  );
};

const Image = styled.img`
  width: 600px;
  height: 250px;
`;

const SmallImage = styled.img`
  width: 150px;
  height: 120px;
`;
const Description = styled.textarea`
  font-size: 12x;
  display: inline-block;
  width: 500px;
  cursor: pointer;
  resize: none;
  background-color: transparent;
  border: none;
  height: 150px;
  @media only screen and (max-width: 1000px) {
    width: 300px;
  }
`;
const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media only screen and (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Masterpieces = styled.div`
  background-color: white;
  padding: 10px;
  margin: 10px;

  &:hover {
    background-color: #eeeeee;
  }
`;

export default MasterpieceAdmin;
