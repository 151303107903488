import axios from "axios";
import BasedUrl from "../constrants";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Zoom from "react-reveal/Zoom";

const Masterpieces = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    try {
      axios.get(BasedUrl + "/masterpieces/getAll").then((res) => {
        setData(res.data);
      });
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <Page>
      <Container>
        <Zoom>
          <div>
            <List>
              {data.map((d) => (
                <P key={d.uuid}>
                  {props.language === "LA" && (
                    <div className="col">
                      <h5 />
                      {d.title}
                      <h5 />
                      <label className="sr-only">{d.info}</label>
                      <div>
                        <img className="ww-50 mb-3 mt-3" src={d.image} />
                      </div>
                    </div>
                  )}
                  {props.language === "EN" && (
                    <div className="col">
                      <h5 />
                      {d.titleE}
                      <h5 />
                      <label className="sr-only">{d.infoE}</label>
                      <div>
                        <img className="ww-50 mb-3 mt-3" src={d.image} />
                      </div>
                    </div>
                  )}
                </P>
              ))}
            </List>
          </div>
        </Zoom>
      </Container>
    </Page>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
`;
const Page = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Container = styled.div`
  margin-top: 160px;
  margin-bottom: 20px;
  min-height: 600px;
  max-width: 1610px;
`;

const P = styled.div`
  overflow-wrap: anywhere;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
  padding: 20px;
  background-color: white;
  padding-top: 30px;
`;

export default Masterpieces;
