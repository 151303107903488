import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BasedUrl from "../../constrants";
import Cataloges from "../../pages/Cataloges";

function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

const CatalogesAdmin = () => {
  const [link, setLink] = useState("");
  const [img, setImg] = useState("");
  const [info, setInfp] = useState("");
  const [title, setTitle] = useState("");
  const [catalog, setCatalog] = useState({});

  const onAdd = () => {
    setCatalog({
      link: link,
      title: title,
      info: info,
      img: img,
    });
  };

  useEffect(() => {
    if (!isEmptyObject(catalog)) {
      axios
        .post(BasedUrl + "/cataloges/add", catalog)
        .then((res) => {
          alert("ເພິ່ມສຳເລັດ");
        })
        .catch((err) => {
          alert("ມີບາງຢ່າງເກີດຂຶ້ນ ກະລຸນາລອງໃໝ່ພາຍຫລັງ");
        });
    }
  }, [catalog]);

  return (
    <div className="bg-white container mt-5 p-5 text-center">
      <div className="d-flex justify-content-center col mt-4">
        <img className="w-25" src={img} />
      </div>
      <div className="d-flex justify-content-center col mt-4">
        <input
          onChange={(e) => setImg(e.target.value)}
          value={img}
          placeholder="ລິງຮູບພາບ"
          className="form-control w-50"
        />
      </div>
      <div className="d-flex justify-content-center col mt-4">
        <input
          onChange={(e) => setLink(e.target.value)}
          value={link}
          placeholder="ລິງໄຟຣ"
          className="form-control w-50"
        />
      </div>
      {/* <div className="d-flex justify-content-center col mt-4">
        <input
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder="ຫົວຂໍ້ແຄັດຕາລ໋ອກ"
          className="form-control w-50"
        />
      </div>
      <div
        onChange={(e) => setInfp(e.target.value)}
        value={info}
        className="d-flex justify-content-center col mt-4"
      >
        <textarea placeholder="ຄຳອະທິບາຍ" className="form-control w-50" />
      </div> */}
      <div className="d-flex justify-content-center col mt-4">
        <button onClick={onAdd} className="btn btn-primary w-50">
          ເພິ່ມ
        </button>
      </div>
      <Cataloges admin="ok" />
    </div>
  );
};

export default CatalogesAdmin;
