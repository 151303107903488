import axios from "axios";
import BasedUrl from "../../constrants";
import { useEffect, useState } from "react";
import styled from "styled-components";
import YouTube from "react-youtube";

const addVideos = async (link) => {
  try {
    await axios.post(BasedUrl + "/videos/add", link).then((res) => {
      alert("ເພີ່ມວິວີໂອສຳເລັດ");
    });
  } catch (error) {
    alert("ເກີດບັນຫາໃນການເພີ່ມ ກະລູນາລອງໃໝ່: \n" + error);
  }
};

const deleteVideo = (uuid) => {
  const Uuid = {
    uuid: uuid,
  };
  axios
    .delete(BasedUrl + "/videos/deleteOne", { data: Uuid })
    .then((res) => {
       alert("ລົບວິດີໂອສຳເລັດ");
    })
    .catch((error) => {
      alert("ມິບາງຢ່າງເກີດຂຶ່ນ ລອງໃໝ່ພາຍຫລັງ: \n" + error);
    });
};

const VideosAdmin = () => {
  const [link, setLink] = useState("");
  const [link2, setLink2] = useState("");
  const [videos, setVideos] = useState([]);
  const [trigger, setTrigger] = useState(false);

  const opts = {
    height: "120",
    width: "200",
    playerVars: {
      autoplay: 0,
    },
  };

  const handleChange = (event) => {
    setLink(event.target.value);
  };

  useEffect(() => {
    if (link2 !== "") {
      addVideos({ link: link2 });
    }
  }, [link2]);

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/videos/getAll").then((res) => {
        setVideos(res.data);
        setTrigger(false);
      });
    } catch (error) {
      return error;
    }
  }, [trigger]);

  return (
    <div className="container text-center ">
      <div className="row bg-white mt-5 pt-5 pb-5">
        <div className="d-flex justify-content-center row">
          <YouTube videoId={link} opts={opts} />
          <input
            className="form-control text-center w-25 mt-2"
            type="text"
            placeholder="ໄອດີວີດີໂອ"
            onChange={handleChange}
            value={link}
          />
        </div>
        <div className="d-flex justify-content-center row mt-3">
          <button
            onClick={() => {
              setLink2(link);
              setTrigger(true);
            }}
            className="btn btn-primary w-25"
          >
            ເພີ່ມ
          </button>
        </div>
      </div>
      <div className="row bg-white mt-5 p-5">
        <List>
          {videos.map((video) => (
            <Video>
              <YouTube videoId={video.link} opts={opts} />
              <button
                onClick={() => {
                  if (
                    window.confirm("ທ່ານຕ້ອງການລົບວິດີໂອນີ້ແທ້ບໍ່?") === true
                  ) {
                    deleteVideo(video.uuid);
                    setTrigger(true);
                  }
                }}
                className="btn btn-danger w-50 mt-2 mb-2"
              >
                ລົບ
              </button>
            </Video>
          ))}
        </List>
      </div>
    </div>
  );
};

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Video = styled.div`
  padding: 10px;
  &:hover {
    background-color: #eeeeee;
  }
`;

export default VideosAdmin;
