import Products from "../components/Products";
import styled from "styled-components";
import TopSlide from "../components/TopSlide";
import CenterSilde from "../components/CenterSlide";
import SideBar from "../components/Sidebar";
import { useState } from "react";
import ProductsDetails from "./ProductsDetails";
import Using from "../components/Using";
import UsingDetail from "../components/UsingDetail";
import ImageViewver from "./ImageViewer";
import { useNavigate } from "react-router-dom";

const a = false;
const Home = (props) => {
  const [productClicked, setProductClicked] = useState(a);
  const [usingClicked, setUsingClicked] = useState(false);
  const [uuid, setUuid] = useState("");
  const [img, setImg] = useState("");
  const [showImg, setShowimg] = useState(false);

  const onImageClick = (img) => {
    setImg(img);
    setShowimg(true);
  };

  const HandleProductClicked = (uuid) => {
    setProductClicked(true);
    setUuid(uuid);
  };

  const HandleUsingClicked = (uuid) => {
    setUuid(uuid);

    setUsingClicked(true);
  };

  const HandleOnUsingBack = () => {
    setUsingClicked(false);
  };
  const ImaageList = () => {
    return (
      <div className="py-3">
        <div className="isLarge">
          <div className="myrow">
            <div className="col-3 m-0 ps-2  text-center">
              <img
                onClick={() => {
                  onImageClick("/pic/1.png");
                }}
                className="myimg"
                src="/pic/1.png"
              />
            </div>
            <div className="col-9">
              <div className="myrow">
                <img
                  onClick={() => {
                    onImageClick("/pic/2.png");
                  }}
                  className="myimg-small"
                  src="/pic/2.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/3.png");
                  }}
                  className="myimg-small"
                  src="/pic/3.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/4.png");
                  }}
                  className="myimg-small"
                  src="/pic/4.png"
                />
              </div>
              <div className="myrow mt-2">
                <img
                  onClick={() => {
                    onImageClick("/pic/5.png");
                  }}
                  className="myimg-small"
                  src="/pic/5.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/6.png");
                  }}
                  className="myimg-small"
                  src="/pic/6.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/7.png");
                  }}
                  className="myimg-small"
                  src="/pic/7.png"
                />
              </div>
            </div>
          </div>
          <div className="myrow mt-2">
            <div className="col-9  ps-2">
              <div className="myrow">
                <img
                  onClick={() => {
                    onImageClick("/pic/8.png");
                  }}
                  className="myimg-small"
                  src="/pic/8.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/9.png");
                  }}
                  className="myimg-small"
                  src="/pic/9.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/10.png");
                  }}
                  className="myimg-small"
                  src="/pic/10.png"
                />
              </div>
              <div className="myrow mt-2">
                <img
                  onClick={() => {
                    onImageClick("/pic/11.png");
                  }}
                  className="myimg-small"
                  src="/pic/11.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/12.png");
                  }}
                  className="myimg-small"
                  src="/pic/12.png"
                />
                <img
                  onClick={() => {
                    onImageClick("/pic/13.png");
                  }}
                  className="myimg-small"
                  src="/pic/13.png"
                />
              </div>
            </div>
            <div className="col-3 p-0 m-0">
              <div>
                <img
                  onClick={() => {
                    onImageClick("/pic/14.png");
                  }}
                  className="myimg"
                  src="/pic/14.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mygrid">
          <img
            onClick={() => {
              onImageClick("/pic/1.png");
            }}
            className="myimg-small"
            src="/pic/1.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/2.png");
            }}
            className="myimg-small"
            src="/pic/2.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/3.png");
            }}
            className="myimg-small"
            src="/pic/3.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/4.png");
            }}
            className="myimg-small"
            src="/pic/4.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/5.png");
            }}
            className="myimg-small"
            src="/pic/5.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/6.png");
            }}
            className="myimg-small"
            src="/pic/6.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/7.png");
            }}
            className="myimg-small"
            src="/pic/7.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/8.png");
            }}
            className="myimg-small"
            src="/pic/8.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/9.png");
            }}
            className="myimg-small"
            src="/pic/9.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/10.png");
            }}
            className="myimg-small"
            src="/pic/10.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/11.png");
            }}
            className="myimg-small"
            src="/pic/11.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/12.png");
            }}
            className="myimg-small"
            src="/pic/12.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/13.png");
            }}
            className="myimg-small"
            src="/pic/13.png"
          />
          <img
            onClick={() => {
              onImageClick("/pic/14.png");
            }}
            className="myimg-small"
            src="/pic/14.png"
          />
        </div>
      </div>
    );
  };

  const onCloseImage = () => {
    setShowimg(false);
  };

  const navigate = useNavigate();

  const New = () => {
    return (
      <div className="gri">
        <img
          onClick={() => navigate("/img", { state: 1 })}
          className="iimage"
          src="/i/3.png"
        />
        <img
          onClick={() => navigate("/img", { state: 2 })}
          className="iimage"
          src="/i/1.png"
        />
        <img
          onClick={() => navigate("/img", { state: 3 })}
          className="iimage"
          src="/i/2.png"
        />
      </div>
    );
  };

  return (
    <Container>
      {showImg && <ImageViewver onClose={onCloseImage} img={img} />}
      {usingClicked && (
        <div>
          <UsingDetail uuid={uuid} Back={HandleOnUsingBack} />
        </div>
      )}
      {productClicked && (
        <ProductsDetails language={props.language} uuid={uuid} />
      )}
      {!productClicked && !usingClicked && (
        <>
          <MyRow>
            <TopSlide />
          </MyRow>
          <MyRow>
            <SideBar />
            <New />
          </MyRow>
          <Mobile>
            <MyRow>
              {props.language === "EN" && (
                <img
                  className="img-fluid w-100 px-5 py-5"
                  src="/expresse.png"
                />
              )}
              {props.language === "LA" && (
                <img
                  className="img-fluid w-100 px-5 py-5"
                  src="/expressl.png"
                />
              )}
            </MyRow>
          </Mobile>
          <Desktop>
            <MyRow>
              {props.language === "EN" && (
                <img className="img-fluid w-50 px-5 py-5" src="/expresse.png" />
              )}
              {props.language === "LA" && (
                <img className="img-fluid w-50 px-5 py-5" src="/expressl.png" />
              )}
            </MyRow>
          </Desktop>
          <MyRow>
            <ImaageList />
          </MyRow>
          <MyRow className="py-5">
            <Products
              language={props.language}
              all="no"
              ProductClick={HandleProductClicked}
            />
          </MyRow>
          <Desktop>
            <MyRow className="py-5">
              {props.language === "EN" && (
                <img className="img-fluid w-75" src="/propl.png" />
              )}
              {props.language === "LA" && (
                <img className="img-fluid w-75" src="/prope.png" />
              )}
            </MyRow>
          </Desktop>
          <Mobile>
            <MyRow className="px-3 py-3">
              {props.language === "EN" && (
                <img className="img-fluid w-100" src="/propl.png" />
              )}
              {props.language === "LA" && (
                <img className="img-fluid w-100" src="/prope.png" />
              )}
            </MyRow>
          </Mobile>
          <MyRow>
            <div className=" mixed text-center mt-2 pb-5">
              {props.language === "EN" && (
                <Mixed>
                  <div className="collumn mt-5">
                    <Text>➢ Naturally beauty </Text>
                    <Text>➢ Give the skin a touch and feel like real wood</Text>
                    <Text>➢ No need of paint, The color does not fade</Text>
                    <Text>➢ Use technology with click lock system</Text>
                    <Text>➢ Easy, fast and time-saving installation</Text>
                    <Text>➢ Distinctive and stylish above the level </Text>
                    <Text>➢ No problem with termite and Fungus</Text>
                    <Text>➢ Safe and environmentally friendly </Text>
                    <Text>➢ It is a recycled material</Text>
                  </div>
                  <img className="img-fluid w-100 mw" src="/mixede.png" />
                </Mixed>
              )}
              {props.language === "LA" && (
                <Mixed>
                  <div className="collumn mt-5">
                    <Text>➢ ງາມແບບທຳມະຊາດ</Text>
                    <Text>➢ ໃຫ້ຜິວສຳຜັດ ແລະ ໃຫ້ງານແບບທຳມະຊາດ</Text>
                    <Text>
                      ➢ ໃຫ້ຜິວສຳຜັດ ແລະ ໃຫ້ອາລົມຄວາມຣູ້ສຶກຄ້າຍຄືໄມ້ແທ້
                    </Text>
                    <Text>➢ ສີບໍ່ຈືດຈາງ ບໍ່ຈຳເປັນຕ້ອງທາສີ</Text>
                    <Text>➢ ນຳໃຊ້ເຕັກໂນໂລຊີລະບົບຄລິກລ໋ອກ</Text>
                    <Text>➢ ຕິດຕັ້ງງ່າຍ ແລະ ໄວປະຢັດເວລາ</Text>
                    <Text>➢ ໂດດເດັ່ນ ແລະ ມີສະໄຕເຫນືອລະດັບ</Text>
                    <Text>
                      ➢ ຫມົດບັນຫາເລື່ອງປວກ ແລະ ບໍ່ຂຶ້ນເຫັດ, ບໍ່ຂຶ້ນເຊື້ອລາ
                    </Text>
                    <Text>➢ ປອດໄພ ແລະ ເປັນມິດກັບສິ່ງແວດລ້ອມ</Text>
                    <Text>➢ ເປັນວັດສະດຸຫມຸນວຽນກັບມາໃຊ້</Text>
                  </div>
                  <div className="">
                    <img className="img-fluid w-100 mw" src="/mixedl.png" />
                  </div>
                </Mixed>
              )}
            </div>
          </MyRow>
        </>
      )}
    </Container>
  );
  // return (
  //   <Zoom>
  //     <Container>
  //       <C>
  //         {usingClicked && (
  //           <div>
  //             <UsingDetail uuid={uuid} Back={HandleOnUsingBack} />
  //           </div>
  //         )}
  //         {productClicked && (
  //           <ProductsDetails language={props.language} uuid={uuid} />
  //         )}
  //         {!productClicked && !usingClicked && (
  //           <div className="col">
  //             <TopSlide />
  //             {/* <Column>
  //             <SideBar />
  //             <Using Click={HandleUsingClicked} language={props.language} />

  //             </Column> */}
  //             {/* <div className="bg-white  text-center mt-2">
  //               {props.language === "EN" && (
  //                   <img className="img-fluid w-75" src="/expresse.png" />
  //                 )}
  //                 {props.language === "LA" && (
  //                   <img className="img-fluid w-75" src="/expressl.png" />
  //                 )}
  //             </div>
  //             <Products
  //               language={props.language}
  //               all="no"
  //               ProductClick={HandleProductClicked}
  //             />
  //             <div className="bg-white mt-2 text-center py-3">
  //               {props.language === "EN" && (
  //                 <img className="img-fluid w-50" src="/propl.png" />
  //               )}
  //               {props.language === "LA" && (
  //                 <img className="img-fluid w-50" src="/prope.png" />
  //               )}
  //             </div>{" "}
  //             <div className=" mixed text-center mt-2 pb-5">
  //               {props.language === "EN" && (
  //                 <Mixed>
  //                   <div className="collumn mt-5">
  //                     <Text>➢ Naturally beauty </Text>
  //                     <Text>
  //                       ➢ Give the skin a touch and feel like real wood
  //                     </Text>
  //                     <Text>➢ No need of paint, The color does not fade</Text>
  //                     <Text>➢ Use technology with click lock system</Text>
  //                     <Text>➢ Easy, fast and time-saving installation</Text>
  //                     <Text>➢ Distinctive and stylish above the level </Text>
  //                     <Text>➢ No problem with termite and Fungus</Text>
  //                     <Text>➢ Safe and environmentally friendly </Text>
  //                     <Text>➢ It is a recycled material</Text>
  //                   </div>
  //                   <img className="img-fluid w-75" src="/mixede.png" />
  //                 </Mixed>
  //               )}
  //               {props.language === "LA" && (
  //                 <Mixed>
  //                   <div className="collumn mt-5">
  //                     <Text>➢ ງາມແບບທຳມະຊາດ</Text>
  //                     <Text>➢ ໃຫ້ຜີວສຳພັດ ແລະ ໃຫ້ງານແບບທຳມະຊາດ</Text>
  //                     <Text>
  //                       ➢ ໃຫ້ຜີວສຳພັດ ແລະ ໃຫ້ອາລົມຄວາມຣູ້ສຶກຄ້າຍຄືໄມ້ແທ້
  //                     </Text>
  //                     <Text>➢ ສີບໍ່ຈືດຈາງ ບໍ່ຈຳເປັນທາສີ</Text>
  //                     <Text>➢ ນຳໃຊ້ເທັກໂນໂລຢີລະບົບຄລິກລ໋ອກ</Text>
  //                     <Text>➢ ຕິດຕັ້ງງ່າຍ ແລະ ໄວປະຢັດເວລາ</Text>
  //                     <Text>➢ ໂດດເດັ່ນ ແລະ ມີສະໄຕເຫນືອລະດັບ</Text>
  //                     <Text>➢ ຫມົດບັນຫາເລຶ່ອງແວກ ແລະ ເຫັດເຊື້ອລາຂຶນ</Text>
  //                     <Text>➢ ແອດໄະ ແລະ ເປັນມິດກັບສິ່ງແວດລ້ອມ</Text>
  //                     <Text>➢ ເປັນວັດສະດູຫມຸນວຽນກັບມາໃຊ້</Text>
  //                   </div>
  //                   <div className="col d-flex align-items-center">
  //                     <img className="img-fluid w-75" src="/mixedl.png" />
  //                   </div>
  //                 </Mixed>
  //               )}
  //             </div> */}
  //           </div>
  //         )}
  //       </C>
  //     </Container>
  //   </Zoom>
  // );
};

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 700px) {
    display: flex;
  }
`;

const Desktop = styled.div`
  display: flex;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const MyRow = styled.div`
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  width: 1610px;
  align-items: center;
  align-content: center;
  background-color: white;
  justify-content: center;
  @media only screen and (max-width: 1600px) {
    width: 1200px;
  }
  @media only screen and (max-width: 699px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  margin-top: 5px;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 860px) {
    display: block;
  }
`;

const Text = styled.label`
  margin-bottom: 3px;
  text-align: start;
  width: 400px;
  @media only screen and (max-width: 760px) {
    width: 100%;
    font-size: 16px;
    margin-left: 0px;
  }
`;

const Mixed = styled.div`
  color: black;
  background-color: white;
  display: grid;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  align-content: centert;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
  @media only screen and (max-width: 999px) {
    padding-left: 20px;
    padding-right: 20px;
    align-content: centert;
    align-items: start;
    justify-content: center;
    flex-direction: column;
  }
`;
export default Home;
