import styled from "styled-components";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import BasedUrl from "../constrants";
import Zoom from "react-reveal/Zoom";
import { installingList } from "../constrants";

const sideScroll = (element, step) => {
  element.scrollLeft += step;
};

const Using = (props) => {
  // const [productsList, setProductList] = useState([]);

  // useEffect(() => {
  //   try {
  //     axios.get(BasedUrl + "/products/getAll").then((res) => {
  //       setProductList(res.data);
  //     });
  //   } catch (error) {
  //     return error;
  //   }
  // }, []);

  const [section, setSection] = useState(0);
  const contentWrapper = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setSection((c) => c + 700);
      sideScroll(contentWrapper.current, section);
      if (section > 2000) {
        setSection(-2500);
      }
    }, 2000);
  }, [section]);

  return (
    <Row ref={contentWrapper}>
      {installingList.map((data) => (
        <Zoom>
          <U key={data}>
            {props.language === "LA" && (
              <label>{"ການຕິດຕັ້ງ " + data.name}</label>
            )}
            {props.language === "EN" && <label>{"Using " + data.nameE}</label>}
            <Image src={"/installing/" + data.name + ".png"} />
          </U>
        </Zoom>
      ))}
    </Row>
  );
};

const Row = styled.div`
  margin-top: 7px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  width: 1310px;
  scroll-behavior: smooth;
  align-content: center;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    width: 895px;
  }
  @media only screen and (max-width: 999px) {
    padding-top: 0px;
    overflow-x: scroll;
    padding-bottom: 10px;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 500px;
  height: 360px;
  padding: 20px;
  @media only screen and (max-width: 2900px) {
    width: 500px;
    height: 400px;
  }
  @media only screen and (max-width: 1600px) {
    width: 500px;
    height: 320px;
  }
`;

// const Image = styled.img`
//   width: 300px;
//   height: 360px;
//   padding: 20px;
//   @media only screen and (max-width: 2900px) {
//     width: 280px;
//     height: 400px;
//   }
//   @media only screen and (max-width: 1600px) {
//     width: 250px;
//     height: 320px;
//   }
// `;

const U = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  &: hover {
    background-color: #eeee;
  }
`;

export default Using;
