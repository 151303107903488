import Product from "./Product";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import BasedUrl from "../constrants";
import ProductsDetails from "../pages/ProductsDetails";

function ProductsSuggest(props) {
  const [productsList, setProductList] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [uuid, setUuid] = useState("");

  useEffect(() => {
    try {
      axios.get(BasedUrl + "/products/getSuggest").then((res) => {
        setProductList(res.data);
      });
    } catch (error) {
      return error;
    }
  }, []);

  const omClosed = () => {
    setClicked(false);
  };
  return (
    <Container>
      {clicked && (
        <ProductsDetails
          language={props.language}
          uuid={uuid}
          Close={omClosed}
        />
      )}
      {!clicked && (
        <Column>
          {productsList.map((product) => (
            <div
              key={product.uuid}
              onClick={() => {
                setClicked(true);
                setUuid(product.uuid);
              }}
            >
              <Product
                key={product.uuid}
                uuid={product.uuid}
                ProductName={product.p_name}
                ImageUrl={product.img1}
                Size={product.size}
              />
            </div>
          ))}
        </Column>
      )}
    </Container>
  );
}

export default ProductsSuggest;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 500px;
  margin-top: 120px;
`;

const Column = styled.div`
  padding-top: 100px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  overflow-x: scroll;
  overflow=: hidden;
  width: 100%;
  scroll-behavior: smooth;

  @media only screen and (max-width: 760px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 560px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
