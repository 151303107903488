import styled from "styled-components";

const Post = (props) => {
  const posts = props.posts;
  return (
    <div className="bg-white">
      {props.deleted === "no" && (
        <Container>
          <Image alt="" src={props.ImageUrl} />
          <label>{props.Topic}</label>
        </Container>
      )}
      {props.deleted === "ok" && (
        <DeleteContainer>
          <div className="row text-center">
            <div className="col">
              <Image className="mt-4" alt="" src={props.ImageUrl} />
              <h4>{props.Topic}</h4>
            </div>
          </div>
          <Description readOnly>{props.Description}</Description>
          <div>
            <button className="btn btn-danger w-50">ລົບ</button>
          </div>
        </DeleteContainer>
      )}
    </div>
  );
};

const DeleteContainer = styled.div`
  padding-bottom: 30px;
  &:hover {
    background-color: #eeeeee;
  }
`;

const Container = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 300px;
  height: auto;
  padding: 10px;
  align-content: start;
  align-items: center;
  justify-content: start;
  &:hover {
    background-color: #eeeeee;
  }
  @media only screen and (max-width: 860px) {
    flex-direction: column;
    width: 200px;
  }
`;
const Image = styled.img`
  width: 90px;
  height: 80px;
`;

const Description = styled.textarea`
  font-size: 12x;
  display: inline-block;
  width: 200px;
  cursor: pointer;
  resize: none;
  background-color: transparent;
  border: none;
  height: 100px;
`;

export default Post;
