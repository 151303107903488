import styled from "styled-components";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import BasedUrl from "../constrants";
import { useNavigate } from "react-router-dom";

const Search = (props) => {
  const navigate = useNavigate();
  const [products, setProducrs] = useState([]);

  useEffect(() => {
    axios.get(BasedUrl + "/products/getAll").then((res) => {
      setProducrs(res.data);
    });
  }, []);

  const handleOnSelect = (item) => {
    // the item selected
    navigate("/pdt", {
      state: { uuid: item.uuid },
    });
  };
  const formatResult = (item) => {
    return (
      <>
        <label style={{ display: "block", textAlign: "left" }}>
          {item.p_name}
        </label>
      </>
    );
  };

  const option = {
    keys: ["p_name"],
  };

  const style = {
    height: props.height,
  };
  return (
    <Container>
      <ReactSearchAutocomplete
        items={products}
        fuseOptions={option}
        // onSelect={handleOnSelect}
        formatResult={formatResult}
        resultStringKeyName="p_name"
        onSelect={handleOnSelect}
        styling={style}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
`;

export default Search;
