import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import BasedUrl from "./constrants";

const root = ReactDOM.createRoot(document.getElementById("root"));

const addVisitor = async () => {
  await axios.post(BasedUrl + "/visitors/add").then((res) => {});
};

addVisitor();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
